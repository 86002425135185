import React, { useState } from 'react';
import { Link, useNavigate } from "react-router-dom";
import { FcGoogle } from "react-icons/fc";
import { AiOutlineTwitter } from "react-icons/ai";
import { motion } from "framer-motion";
import { signInWithEmailAndPassword } from "firebase/auth";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { auth } from "../../firebase.config";
import { signInWithPopup, GoogleAuthProvider } from "firebase/auth";
import { useSelector } from "react-redux";
import { selectPreviousURL } from '../../context/Slice/cartSlice';



const Login = () => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    const navigate = useNavigate();
    const previousURL = useSelector(selectPreviousURL);
  

  const redirectUser = () => {
    if(previousURL.includes("cart")){
      return navigate("/cart");
    }
    else{
      navigate("/");
    }
  }

    
    const loginUser = (e) => {
        e.preventDefault();
        //console.log(email, password);
    
            signInWithEmailAndPassword(auth, email, password)
            .then((userCredential) => {
            
                //const user = userCredential.user;

                //console.log(user);

                toast.success(" Login successful...");
              redirectUser();
                
            })
            .catch((error) => {
                toast.error("Oops! You are not connected");
            });
    };

    //######## Login with google ########
    const provider = new GoogleAuthProvider();
    const signinWithGoogle = () =>{
        signInWithPopup(auth, provider)
        .then((result) => {
            //const user = result.user;
            toast.success(" Login successful...");
            redirectUser();
            // ...
        }).catch((error) => {
            // Handle Errors here.
             toast.error("Oops! You are not connected");
            // ...
        });
    }

  return (
    <>
        
      <div className="relative flex flex-col justify-center min-h-screen overflow-hidden -top-8 -mt-14 lg:mt-0">
        <div className="w-full lg:w-[340px] p-6 m-auto bg-white rounded-3xl shadow-2xl lg:max-w-xl">
          <h1 className="text-xl font-semibold text-center text-gray-400">
            Sign in
          </h1>
          <form className="mt-6" onSubmit={loginUser}>
            <div className="mb-2">
              <label
                for="email"
                className="block text-sm font-semibold text-gray-800"
              >
                Email
              </label>
              <input
                type="email"
                placeholder="mail@example.com"
                className="block w-full px-4 py-2 mt-2 text-gray-600 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required />
            </div>
            <div className="mb-2">
              <label
                for="password"
                className="block text-sm font-semibold text-gray-800"
              >
                Password
              </label>
              <input
                type="password"
                placeholder="******************"
                className="block w-full px-4 py-2 mt-2 text-gray-600 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required />
            </div>
            <Link to="/Reset" className="text-xs text-pink-600 hover:underline" >
                <span>Forget Password?</span>
            </Link>
            
            <div className="mt-6">
              <motion.button type="submit" whileTap={{ scale: 0.75 }} className="w-full px-4 py-2 tracking-wide text-white transition-colors duration-200 transform bg-urmallColor rounded-md hover:bg-pink-700 focus:outline-none focus:bg-pink-600">
                Login
              </motion.button>
            </div>
          </form>
          <div className="relative flex items-center justify-center w-full mt-6 border border-t">
            <div className="absolute px-5 bg-white">Or</div>
          </div>
          <div className="flex mt-4 gap-x-2">
            <motion.button 
                whileTap={{ scale: 0.75 }}
                type="button"
                className="flex items-center justify-center w-full p-2 border border-gray-400 text-urmallColor rounded-md focus:ring-2 focus:ring-offset-1 focus:ring-gray-600 hover:bg-urmallColor hover:text-white"
                onClick={() => signinWithGoogle()}
            >
              <FcGoogle className="text-md" />
              
            </motion.button>
            
            <motion.button whileTap={{ scale: 0.75 }} className="flex items-center justify-center w-full p-2 border border-gray-400 rounded-md focus:ring-2 focus:ring-offset-1 focus:ring-gray-600 hover:bg-blue-600 hover:text-white text-blue-600">
              <AiOutlineTwitter />
             
            </motion.button>
          </div>

          <p className="mt-8 text-xs font-light text-center text-gray-700">
            {" "}
            Don't have an account?{" "}
            <Link to="/Register" className="font-medium text-urmallColor hover:underline">
              Sign up
            </Link>
          </p>


          {/*<div className=" space-y-4 text-gray-600 text-center sm:-mb-8">
              <p className="text-xs">By proceeding, you agree to our <a href="#" className="underline">Terms of Use</a> and confirm you have read our <a href="#" className="underline">Privacy and Cookie Statement</a>.</p>
              <p className="text-xs">This site is protected by reCAPTCHA and the <a href="#" className="underline">Google Privacy Policy</a> and <a href="#" className="underline">Terms of Service</a> apply.</p>
          </div>*/}

          
        </div>
      </div>
    </>
  );
}

export default Login