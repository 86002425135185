import React from "react";
import { Link } from "react-router-dom";


const NotFound = () =>{

    return(
        <>
            <div>Oops! Page Not found</div>
            <button><Link to="/#product">&larr; Back to Home</Link></button>
        </>
        

    );
};

export default NotFound;