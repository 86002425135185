import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { selectOrderHistory } from "../../context/Slice/orderSlice";
import { useSelector } from "react-redux";



ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: 'top',
    },
    title: {
      display: true,
      //text: 'Order status Chart',
    },
  },
};



const Chart = () =>{

    const orders = useSelector(selectOrderHistory);

    //create array of order status
    const array = [];
    orders.map((item) => {
        const { orderStatus } = item;
        array.push(orderStatus);
    })
    const getOrderStatusCount = (arr, value) =>{
        return arr.filter((n) => n === value).length

    };
    const [s1, s2, s3, s4] = ["Order Placed...", "Processing...", "Shipped...", "Delivered..."];


    const placed = getOrderStatusCount(array, s1);
    const processing = getOrderStatusCount(array, s2);
    const shipped = getOrderStatusCount(array, s3);
    const delivered = getOrderStatusCount(array, s4);

    const data = {
        labels: ["Placed Orders", "Processing", "Shipped", "Delivered"],
        datasets: [
            {
            label: 'Order Count',
            data: [placed, processing, shipped, delivered],
            backgroundColor: 'rgba(255, 99, 132, 0.8)',
            },
            
        ],
    };




    return(
        <>
            <div className="card w-[700px] h-[430px] bg-white shadow-xl mt-8 py-4 px-4 rounded-lg border-b-2 border-pink-600">
                <h3>Order Status Chart</h3>
                <Bar options={options} data={data} />
            </div>
        </>
        
    );
};

export default Chart;