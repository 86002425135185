import { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { collection, query,  onSnapshot, orderBy, doc } from "firebase/firestore";
import { firestore } from '../firebase.config';






const useFetchCollection = (collectionName) =>{

    const [data, setData] = useState([]);
  //const [isLoading, setIsLoading] = useState(false);


        const getCollection = () =>{
                //setIsLoading(true);    
                try{
                    const docRef = collection(firestore, collectionName);
                    //####### Order and Limit data ####### 
                    const q = query(docRef, orderBy("createdAt", "desc"));
                    //###### Get realtime update ###### Listen to multiple documents in a collection ######
                    onSnapshot(q, (snapshot) => {
                    //####### onSnapshot helps to monitor the document
                    //console.log(snapshot.docs);
                    const allData   = snapshot.docs.map((doc) =>({
                        id: doc.id,
                        ...doc.data()
                    }))
                    
                    setData(allData);
                    //setIsLoading(false);
                    
                    
                    });

                }
                catch(error){
                //setIsLoading(false);
                toast.error(error.message);

                }

        }

         useEffect(() =>{
                getCollection();
            }, [])

            //#### isLoading state inside return #####
            return {data}

}

export default useFetchCollection;