import { useState } from 'react';
import {
  
  ref,
  uploadBytesResumable,
  getDownloadURL,deleteObject,
} from "firebase/storage";

import { firestore, storage } from '../../../firebase.config';
import { toast } from 'react-toastify';
import { collection, addDoc, Timestamp,setDoc, doc  } from "firebase/firestore"; 
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectProducts } from "../../../context/Slice/productSlice";





const category = [
    {id: 1, name: "Chicken"},
     {id: 2, name: "Snacks"},
      {id: 3, name: "Fruit"},
       {id: 4, name: "Ice cream"},
        {id: 5, name: "Rice"},
          {id: 6, name: "Oil"},
          {id: 7, name: "Vegetable"},
          {id: 8, name: "Tubers"},
          {id: 9, name: "Spices"},
          {id: 10, name: "Tea"},
          {id: 11, name: "Drinks"},
          {id: 12, name: "Detergent"},
]
const initialState = {
        name: "",
        imageURL: "",
        price: 0,
        category: "",
        brand: "",
        itemweight: "",
        desc: "",
}
const AddProducts = () => {
  const { id } = useParams();
  const products = useSelector(selectProducts);
  const productEdit = products.find((item) =>item.id === id);
  
    const [product, setProduct ] = useState(() =>{
      const newState = detectForm(id, 
        {...initialState}, productEdit
        )
        return newState;
    });

    const [uploadProgress, setUploadProgress] = useState(0);
    const navigate = useNavigate();
   
   

    function detectForm(id, func1, func2){
      if(id === "ADD"){
        return func1;
      }
      return func2;
    }

    const handleInputChange = (e) =>{
        const {name, value} = e.target;
        setProduct({ ...product, [name]: value });
    };


    const handleImageChange = (e) =>{
        const file = e.target.files[0];
        //console.log(file);

        //########## Receiving image file and storing it in images folder in firebase ##########
        const storageRef = ref(storage, `urmallImages/${Date.now()}${file.name}`);
        //###### Uploading file ##########
        const uploadTask = uploadBytesResumable(storageRef, file);



        uploadTask.on('state_changed', 
            (snapshot) => {
                const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                setUploadProgress(progress);
                
            }, 
            (error) => {
                toast.error("oops! Error while uploading Image");
                
            }, 
            () => {
                
                getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                setProduct({...product, imageURL: downloadURL });
                toast.success(" Image successfully Uploaded...");
                

              });
            }
            );

    };


    const addProduct = (e) =>{
        e.preventDefault();
        //console.log(product);
        
        try{

            // Add a new document with a generated id.
            const docRef = addDoc(collection(firestore, "products"), {
            name: product.name,
            imageURL: product.imageURL,
            price: Number(product.price),
            category: product.category,
            brand: product.brand,
            itemweight: product.itemweight,
            desc: product.desc,
            createdAt: Timestamp.now().toDate()
            });
            setProduct({...initialState});
            setUploadProgress(0)
            toast.success(" Product successfully Uploaded...");
            navigate("/Admin/all-Products");

        }
        catch(error){
            toast.error(error.message);
        }
    };


    //############## Edit Product ####################
    const editProduct = (e) =>{
      e.preventDefault();
      //setIsLoading(true);

      if(product.imageURL !== productEdit.imageURL){

            // Create a reference to the file to delete
            const storageRef = ref(storage, productEdit.imageURL);
          // Delete the file
          deleteObject(storageRef);
      }

       try{

            // Edit a document with a generated id.
            setDoc(doc(firestore, "products", id), {
              name: product.name,
              imageURL: product.imageURL,
              price: Number(product.price),
              category: product.category,
              brand: product.brand,
              itemweight: product.itemweight,
              desc: product.desc,
              createdAt: productEdit.createdAt,
              editedAt: Timestamp.now().toDate(),



            });
            
            //setIsLoading(false);
            setProduct({...initialState});
            setUploadProgress(0)
            toast.success(" Product successfully Edited...", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
            navigate("/Admin/all-Products");

        }
        catch(error){
            toast.error(error.message);
            //setIsLoading(false);
        }
    }


  return (
    <div className="flex ml-40 px-6 py-4 w-screen h-screen">
      
      <section className="bg-white  h-[900px] lg:h-[870px] lg:w-[500px] mb-12 rounded-lg shadow-xl">
        <div className="py-8 px-4 mx-auto max-w-2xl lg:py-16">
          <h2 className="mb-4 text-xl font-bold text-gray-500 ">
            {detectForm(id, "Add New Product", "Edit Product")}
          </h2>
          <form onSubmit={detectForm(id, addProduct, editProduct)}>
            <div className="grid gap-4 sm:grid-cols-2 sm:gap-6">
              <div className="sm:col-span-2">
                <label
                  htmlFor="name"
                  className="block mb-2 text-sm font-medium text-gray-700 "
                >
                  Product Image
                </label>

                {uploadProgress === 0 ? null : (
                  <div className="w-full bg-gray-200 rounded-full dark:bg-gray-700 mb-2">
                    <div
                      className="bg-blue-600 text-xs font-medium text-blue-100 text-center p-0.5 leading-none rounded-full"
                      style={{ width: `${uploadProgress}%` }}
                    >
                      {" "}
                      {uploadProgress < 100
                        ? `Uploading ${uploadProgress}`
                        : `Upload Completed ${uploadProgress}%`}
                    </div>
                  </div>
                )}

                <input
                  className="block w-full text-sm text-gray-900 bg-gray-50 p-2.5  rounded-lg border border-gray-300 cursor-pointer dark:text-gray-400 focus:outline-none  dark:border-gray-300 dark:placeholder-gray-400"
                  aria-describedby="user_avatar_help"
                  id="user_avatar"
                  type="file"
                  accept="image/*"
                  name="image"
                  onChange={(e) => handleImageChange(e)}
                />

                {product.imageURL === 0 ? (
                  ""
                ) : (
                  <input
                    type="text"
                    name="imageURL"
                    value={product.imageURL}
                    id="imageURL"
                    className="bg-gray-50 border border-gray-300 text-gray-700 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5  dark:placeholder-gray-400  dark:focus:ring-primary-500 dark:focus:border-primary-500 mt-2"
                    placeholder="Image URL"
                    disabled
                  />
                )}
              </div>
              <div className="sm:col-span-2">
                <label
                  htmlFor="name"
                  className="block mb-2 text-sm font-medium text-gray-700 "
                >
                  Product Name
                </label>
                <input
                  type="text"
                  name="name"
                  value={product.name}
                  onChange={(e) => handleInputChange(e)}
                  className="bg-gray-50 border border-gray-300 text-gray-700 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5  dark:placeholder-gray-400  dark:focus:ring-primary-500 dark:focus:border-primary-500"
                  placeholder="Type product name"
                  required=""
                />
              </div>
              <div className="w-full">
                <label
                  htmlFor="brand"
                  className="block mb-2 text-sm font-medium text-gray-700 "
                >
                  Brand
                </label>
                <input
                  type="text"
                  name="brand"
                  value={product.brand}
                  onChange={(e) => handleInputChange(e)}
                  id="brand"
                  className="bg-gray-50 border border-gray-300 text-gray-700 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5  dark:placeholder-gray-400  dark:focus:ring-primary-500 dark:focus:border-primary-500"
                  placeholder="Product brand"
                  required=""
                />
              </div>
              <div className="w-full">
                <label
                  htmlFor="price"
                  className="block mb-2 text-sm font-medium text-gray-700 "
                >
                  Price
                </label>
                <input
                  type="number"
                  name="price"
                  value={product.price}
                  onChange={(e) => handleInputChange(e)}
                  id="price"
                  className="bg-gray-50 border border-gray-300 text-gray-700 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5  dark:placeholder-gray-400  dark:focus:ring-primary-500 dark:focus:border-primary-500"
                  placeholder="N2999"
                  required=""
                />
              </div>
              <div>
                <label
                  htmlFor="category"
                  className="block mb-2 text-sm font-medium text-gray-700 "
                >
                  Category
                </label>
                <select
                  id="category"
                  className="bg-gray-50 border border-gray-300 text-gray-700 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5  dark:placeholder-gray-400  dark:focus:ring-primary-500 dark:focus:border-primary-500"
                  name="category"
                  value={product.category}
                  onChange={(e) => handleInputChange(e)}
                >
                  <option selected>Select category</option>
                  {category.map((cat) => {
                    return (
                      <option key={cat.id} value={cat.name}>
                        {cat.name}
                      </option>
                    );
                  })}
                </select>
              </div>
              <div>
                <label
                  htmlFor="item-weight"
                  className="block mb-2 text-sm font-medium text-gray-700 "
                >
                  Item Weight (kg)
                </label>
                <input
                  type="number"
                  name="itemweight"
                  value={product.itemweight}
                  onChange={(e) => handleInputChange(e)}
                  id="item-weight"
                  className="bg-gray-50 border border-gray-300 text-gray-700 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5  dark:placeholder-gray-400  dark:focus:ring-primary-500 dark:focus:border-primary-500"
                  placeholder="12"
                  required=""
                />
              </div>
              <div className="sm:col-span-2">
                <label
                  htmlFor="description"
                  className="block mb-2 text-sm font-medium text-gray-700 "
                >
                  Description
                </label>
                <textarea
                  id="description"
                  name="desc"
                  value={product.desc}
                  onChange={(e) => handleInputChange(e)}
                  rows="8"
                  className="block p-2.5 w-full text-sm text-gray-700 bg-gray-50 rounded-lg border border-gray-300 focus:ring-primary-500 focus:border-primary-500  dark:placeholder-gray-400  dark:focus:ring-primary-500 dark:focus:border-primary-500"
                  placeholder="Your description here"
                ></textarea>
              </div>
            </div>
            <button
              type="submit"
              className="inline-flex items-center px-5 py-2.5 mt-4 sm:mt-6 text-sm font-medium text-center text-white bg-pink-500 rounded-lg focus:ring-4 focus:ring-primary-200  hover:bg-pink-800"
            >
                  {detectForm(id, "Add Product", "Edit Product")}
            </button>
          </form>
        </div>
      </section>
    </div>
  );
}

export default AddProducts;