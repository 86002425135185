import React, {useRef,useEffect, useState} from "react";
//import { MdShoppingBasket } from "react-icons/md";
//import { motion } from "framer-motion";
//import NotFound from '../img/NotFound.svg';
//import { useStateValue } from "../context/StateProvider";
//import { actionType } from "../context/reducer";
//import { useSnackbar } from 'notistack';
import useFetchCollection from "../../customeHook/useFetchCollection";
import { useDispatch, useSelector } from "react-redux";
import { STORE_PRODUCTS, selectProducts, GET_PRICE_RANGE } from "../../context/Slice/productSlice";
import ProductList from "./ProductList";
import ProductFilter from "./ProductFilter";
import CategoryMenu from "./CategoryMenu";


const Product = () => {

//########## using useFetchCollection #######
        const { data } = useFetchCollection("products");
        
  const [showFilter, setShowFilter] = useState(false);
        //const [isLoading, setIsLoading] = useState(false);
        const products = useSelector(selectProducts)
         
        const dispatch = useDispatch();

        useEffect(() =>{
            dispatch(
                STORE_PRODUCTS({
                  products: data,
              }));

          dispatch(
            GET_PRICE_RANGE({
            products: data,
          }));

        }, [dispatch, data]);
      //########## using useFetchCollection #######

  const toggleFilter = () => {
          setShowFilter(!showFilter)
        }


  return (
    <section>
      <p className="text-2xl font-samibold capitalize ml-2.5 text-headingColor relative before:absolute before:rounded-lg before:content before:w-32 before:h-1 before:-bottom-2 before:left-0 before:bg-gradient-to-tr from-pink-400 to-pink-600 transition-all ease-in-out duration-1000 mr-auto">
        What's in our shop
      </p>

      <div className="mt-4">
        <CategoryMenu />
      </div>

      <div
        className={`${showFilter ? "md:container flex " : " grid md:grid-cols-6 "}`}
      >
        <div className="hidden md:grid">
          {showFilter ? (
            <div onClick={() => toggleFilter()} className="container transition-all ease-in-out duration-2000">
                <h2 className="mt-16 text-xl font-normal text-gray-700 cursor-pointer">
                  <ion-icon name="options-outline"></ion-icon>
                </h2>
              </div>
          ) : (
            <>
            <aside  className=" mt-16 border-r border-gray-300 shadow-r-lg transition-all ease-in-out duration-2000">
              <h2 onClick={() => toggleFilter()} className="text-xl font-normal text-gray-700 cursor-pointer mb-6">
                <ion-icon name="close-outline"></ion-icon> <span className="text-sm -mt-1.5">Filter</span>
              </h2>
              <ProductFilter className="" />
            </aside>
              
            </>
          )}
        </div>

        <div className="md:col-span-5  transition-all ease-in-out duration-1000">
          <ProductList products={products} />
        </div>
      </div>

      <div>
        <p>
          <b></b>
        </p>
      </div>
    </section>
  );
}

export default Product;