import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { SET_ACTIVE_USER, REMOVE_ACTIVE_USER } from "../context/Slice/authSlice";
import Logo from "../img/logo.png";
import Avatar from "../img/avatar.png";
import { MdShoppingBasket, MdAdd, MdLogout } from "react-icons/md";
import { motion } from "framer-motion";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { getAuth, signInWithPopup, GoogleAuthProvider } from "firebase/auth";
import { app } from "../firebase.config";
import { useStateValue } from "../context/StateProvider";
import { actionType } from "../context/reducer";
import { auth } from "../firebase.config";
import { signOut } from "firebase/auth";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { onAuthStateChanged } from "firebase/auth";
import ShowOnLogin, { ShowOnLogout } from "./HiddenLinks";
import {AdminOnlyLink } from "./AdminOnlyRoute/AdminOnlyRoute";
import { CALCULATE_TOTAL_QUANTITY, selectCartTotalQuantity }  from "../context/Slice/cartSlice";
import { useSelector } from "react-redux";



import Drawer from 'react-modern-drawer';
import CartContainer from './CartContainer';
import 'react-modern-drawer/dist/index.css';



const Header = () => {

  const navigate = useNavigate(); //###### Use to navigate user #######

  const firebaseAuth = getAuth(app);
  const provider = new GoogleAuthProvider();

  //######## React-redux to save an active user ########
  const dispatcher = useDispatch();
  //########## Select total Quantity to be display on the header ##########
  const cartTotalQuantity = useSelector(selectCartTotalQuantity);
  useEffect(() => {
    dispatcher(CALCULATE_TOTAL_QUANTITY())
  }, []);


  //########## Monitor currently sign-in user ##########
  const [displayName, setDisplayName] = useState("");
  const [displayPhoto, setDisplayPhoto] = useState("");
  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        //console.log(user);
        //const uid = user.uid;
        
        setDisplayPhoto(user.photoURL);
        
        //####### if the user login direct wthout username #############
        if(user.displayName == null){
          const u1 = user.email.substring(0, user.email.indexOf("@"));
          const uName = u1.charAt(0).toUpperCase() + u1.slice(1);
          setDisplayName(uName);
        }
        else{
          setDisplayName(user.displayName);
          setDisplayPhoto(user.photoURL);
        }  
        


        dispatcher(SET_ACTIVE_USER({
          email: user.email,
          userName: user.displayName ? user.displayName : displayName,
          userID: user.uid,
          userPhoto: user.photoURL,
        }))
        
        // ...
      } else {
        // User is signed out
        setDisplayName("");
        setDisplayPhoto("");
        dispatcher(REMOVE_ACTIVE_USER());
      }
    });
  }, [dispatcher, displayName, displayPhoto]);



  const [{ user, cartShow}, dispatch] = useStateValue();

  

  

  

  

   const logoutUser = () =>{
    signOut(auth).then(() => {

        toast.success(" Logout successful...", {
                position: "top-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
              });
              navigate("/Login");

        // Sign-out successful.
      }).catch((error) => {
        toast.error(error.message);
        // An error happened.
      });
  }

   
 
   

  
  
  const activeLink = ({isActive}) =>(isActive ? "border-b-4 text-gray-300 border-urmallColor bg-transparent" : "");
  
  
  const [isOpen, setIsOpen] = React.useState(false);
  const toggleDrawer = () => {
    setIsOpen((prevState) => !prevState);
  };

  return (
    <>
     <Drawer
        open={isOpen}
        onClose={toggleDrawer}
        direction='right' >
      <CartContainer />
    </Drawer>


      
      <header className="w-screen z-50 fixed p-3 px-4 md:p-6 bg-headerColor shadow-md">
        {/* Desktop and Tablet */}
        <div className="hidden md:flex w-full h-[20px] items-center justify-between">
          <Link to={"/"} className="flex items-center gap-2">
            <img
              src={Logo}
              className="w-[8.5rem] object-cover"
              alt="Our Logo"
            />
            {/* <p className="text-headingColor text-xl font-bold"> Connectus.ng</p>*/}
          </Link>

          <div className="flex items-center gap-8">
            <ul className="flex items-center gap-8 flex-wrap -mb-px">
              <NavLink to="/#products" className={activeLink}>
                <li className="text-base text-white  duration-1000 transition-all cursor-pointer ease-in-out inline-block py-5 border-b-4 border-transparent hover:text-gray-300 hover:border-urmallColor hover:bg-transparent">
                  <span className="font-light"> Home </span>
                </li>
              </NavLink>

              <NavLink to="/Contact" className={activeLink}>
                <li className="text-base text-white  duration-1000 transition-all cursor-pointer ease-in-out inline-block py-5 rounded-t-xl border-b-4 border-transparent hover:text-gray-300 hover:border-urmallColor hover:bg-transparent">
                  <span className="font-light">Contact</span>
                </li>
              </NavLink>

              <NavLink to="/Shop" className={activeLink}>
                <li className="text-base text-white  duration-1000 transition-all cursor-pointer ease-in-out inline-block py-5 rounded-t-xl border-b-4 border-transparent hover:text-gray-300 hover:border-urmallColor hover:bg-transparent">
                  <span className="font-light">Shop</span>
                </li>
              </NavLink>


              <AdminOnlyLink>
                <NavLink to="/Admin/Home" className={activeLink}>
                  <li className="text-base text-white  duration-1000 transition-all cursor-pointer ease-in-out inline-block py-5 rounded-t-xl border-b-4 border-transparent hover:text-gray-300 hover:border-urmallColor hover:bg-transparent">
                    <span className="font-light">Admin Menu</span>
                  </li>
                </NavLink>
              </AdminOnlyLink>

              <ShowOnLogin>
                <NavLink to="/Order-history" className={activeLink}>
                  <li className="text-base text-white  duration-1000 transition-all cursor-pointer ease-in-out inline-block py-5 rounded-t-xl border-b-4 border-transparent hover:text-gray-300 hover:border-urmallColor hover:bg-transparent">
                    <span className="font-light">My Order</span>
                  </li>
                </NavLink>
              </ShowOnLogin>

              <ShowOnLogin>
                <li
                  onClick={() => logoutUser()}
                  className="text-base text-white  duration-1000 transition-all cursor-pointer ease-in-out inline-block py-5 rounded-t-xl border-b-4 border-transparent hover:text-gray-300 hover:border-urmallColor hover:bg-transparent"
                >
                  <span className="font-light">Logout</span>
                </li>
              </ShowOnLogin>
              <ShowOnLogout></ShowOnLogout>

              <ShowOnLogin>
                <li className="text-base text-blue-400  inline-block py-5 rounded-t-xl border-transparent ">
                  <span className="font-light">Hi, {displayName.slice(0, 9)}</span>
                </li>
              </ShowOnLogin>
            </ul>

            <motion.div
              initial={{ opacity: 0, scale: 0.3 }}
              animate={{ opacity: 1, scale: 1 }}
              exit={{ opacity: 0, scale: 0.3 }}
              className="flex relative  items-center justify-center animate-bounce text-white text-3xl cursor-pointer "
              onClick={() => toggleDrawer()}
              
            >
              
              <ion-icon name="basket-outline"></ion-icon>
              
                <div className="w-5 h-5 rounded-full bg-cartNumBg inline-flex items-center justify-center absolute -top-2 -right-2">
                  <p className="text-xs text-white font-semibold">
                    {cartTotalQuantity}
                  </p>
                </div>
              
            </motion.div>

            <ShowOnLogin>
              <div className="relative">
                <motion.img
                  whileTap={{ scale: 0.7 }}
                  src={displayPhoto}
                  alt="UserprofileIcon"
                  className="w-10 min-w-[40px] h-10 min-h-[40px] drop-shadow-md cursor-pointer rounded-full "
                />
              </div>
            </ShowOnLogin>
            <ShowOnLogout>
              <Link to="/login">
                <motion.img
                  whileTap={{ scale: 0.7 }}
                  src={Avatar}
                  alt="UserprofileIcon"
                  className="w-10 min-w-[40px] h-10 min-h-[40px] drop-shadow-md cursor-pointer rounded-full "
                />
              </Link>
            </ShowOnLogout>
          </div>
        </div>















        {/*############################## Mobile View ####################################*/}
        <div className="flex items-center justify-between md:hidden w-full h-full">
          <motion.div
            initial={{ opacity: 0, scale: 0.6 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 0.6 }}
            className="flex relative  items-center justify-center animate-bounce text-white text-3xl cursor-pointer"
            onClick={() => toggleDrawer()}
          >
            <ion-icon name="basket-outline"></ion-icon>
            
              <div className="w-5 h-5 rounded-full bg-cartNumBg inline-flex items-center justify-center absolute -top-2 -right-2">
                <p className="text-xs text-white font-semibold">
                    {cartTotalQuantity}
                  </p>
              </div>
            
          </motion.div>






          <Link to={"/"} className="flex items-center gap-2">
            <img
              src={Logo}
              className="w-[6.5rem] object-cover"
              alt="Our Logo"
            />
            {/*<p className="text-headingColor text-xl font-bold"> Connectus.ng</p>*/}
          </Link>




          
        <ShowOnLogin>                  
          <div className="relative">
            <motion.img
              whileTap={{ scale: 0.7 }}
              src={displayPhoto}
              alt="UserprofileIcon"
              className="w-10 min-w-[40px] h-10 min-h-[40px] drop-shadow-md cursor-pointer rounded-full "
              
            />

            
          </div>
          </ShowOnLogin>
          <ShowOnLogout>
            <Link to="/login">
              <motion.img
                whileTap={{ scale: 0.7 }}
                src={Avatar}
                alt="UserprofileIcon"
                className="w-10 min-w-[40px] h-10 min-h-[40px] drop-shadow-md cursor-pointer rounded-full "
              />
            </Link>
          </ShowOnLogout>
        </div>
      </header>
    </>
  );
};

export default Header;
