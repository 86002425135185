import React, { useEffect } from 'react';
import InfoStatBox from "../../infoBox/InfoStatBox";
import { AiFillDollarCircle, AiOutlineShoppingCart } from "react-icons/ai";
import { MdAddShoppingCart } from "react-icons/md";
import { useSelector, useDispatch } from "react-redux";
import { selectProducts, STORE_PRODUCTS } from "../../../context/Slice/productSlice";
import { selectOrderHistory, selectTotalOrderAmount, STORE_ORDERS, CALC_TOTAL_ORDER_AMOUNT } from "../../../context/Slice/orderSlice";
import useFetchCollection from "../../../customeHook/useFetchCollection";
import Chart from "../../chart/Chart";






const earningIcon = <AiFillDollarCircle className="text-pink-700 text-xl" />
const productIcon = <AiOutlineShoppingCart className="text-blue-700 text-xl" />
const orderIcon = <MdAddShoppingCart  className="text-purple-700 text-xl" />



const Home = () => {


  const products = useSelector(selectProducts);
  const orders = useSelector(selectOrderHistory);
  const totalOrderAmount = useSelector(selectTotalOrderAmount);
  const dispatch = useDispatch();


  const fbProduct = useFetchCollection("products");
  const {data} = useFetchCollection("orders");

  useEffect(() =>{
    
    dispatch(STORE_PRODUCTS({
      products: fbProduct.data
    }));
    dispatch(CALC_TOTAL_ORDER_AMOUNT());
    dispatch(STORE_ORDERS(data));
  },[dispatch, data, fbProduct]);



  return (
    <>
    <div className="ml-40 w-70grid grid-rows-3 grid-flow-col gap-4 ">
      <InfoStatBox className={"card px-6 py-6 w-[300px] bg-white border-b-2 border-pink-600 rounded-lg shadow-md text-primary-content hover:shadow-xl"} title={"Earnings"} count={`N${totalOrderAmount}`} icon={earningIcon} />

      <InfoStatBox className={"card px-6 py-6 w-[300px] bg-white border-b-2 border-blue-600 rounded-lg shadow-md text-primary-content hover:shadow-xl"} title={"Product"} count={products.length} icon={productIcon} />

      <InfoStatBox className={"card px-6 py-6 w-[300px] bg-white border-b-2 border-purple-600 rounded-lg shadow-md text-primary-content hover:shadow-xl"} title={"Orders"} count={orders.length} icon={orderIcon} />
      

      
      
      </div>
      <div className="row-span-2 col-span-2 "><Chart /></div>
    </>
  )
}

export default Home;