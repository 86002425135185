import React from 'react';
import { useSelector } from "react-redux";
import { selectCartItems, selectCartTotalAmount, selectCartTotalQuantity } from "../../context/Slice/cartSlice";
import { Link } from "react-router-dom";

const CheckoutSummary = () => {
  const cartItems = useSelector(selectCartItems);
  const cartTotalAmount = useSelector(selectCartTotalAmount);
  const cartTotalQuantity = useSelector(selectCartTotalQuantity);
  const delivery = 1200;
  const serviceCharge = 0.13 * cartTotalAmount;

  const grandTotal = serviceCharge + delivery + cartTotalAmount;
  


  return (
    <>
      <div className="rounded-lg shadow-md border border-gray-300 px-8 py-8 lg:w-[400px] w-[365px] lg:mb-0 mb-4 h-auto">
        <div className="relative z-0 w-full mb-6 group">
          <h1 className="text-lg  text-blue-600 border-b-2 border-t-2 px-2.5 py-2.5">
            Checkout Summary
          </h1>
        </div>

        {cartItems.length === 0 ? (
          <>
            <p>Oops! You don't have any item in your cart</p>
            <Link to="/#products">Back to shop</Link>
          </>
        ) : (
          <>
            <div className="grid md:grid-cols-2 md:gap-6">
              <div className="relative z-0 w-full mb-2 group">
                <h2 className="text-sm">{`Cart Item(s): ${cartTotalQuantity}`}</h2>
              </div>
            </div>

            {cartItems.map((item, index) => {
              const { id, name, price, cartQuantity } = item;

              return (
                <div
                  key={id}
                  className="border-b-2 border-t-2 border-gray-300 mb-4 px-2.5 py-2.5 shadow-sm"
                >
                  <h2 className="font-semibold text-sm">
                    Product: <span className="font-light">{name}</span>
                  </h2>
                  <p className="font-semibold text-sm">
                    Quantity: <span className="font-light">{cartQuantity}</span>
                  </p>
                  <p className="font-semibold text-sm">
                    Unit Price:{" "}
                    <span className="font-light">₦{price.toFixed(2)}</span>
                  </p>
                  <p className="font-semibold text-sm">
                    Set Price:{" "}
                    <span className="font-light">₦{cartQuantity * price}</span>
                  </p>
                </div>
              );
            })}

            <div className="grid grid-cols-2 gap-6 mt-4">
              <div className="relative z-0 w-full mb-2 group">
                <h2 className="text-md font-semibold ">Subtotal:</h2>
              </div>
              <div className="relative z-0 w-full mb-2 group">
                <h2 className="text-md font-semibold ">{`₦${cartTotalAmount.toFixed(
                  2
                )}`}</h2>
              </div>
            </div>

            <div className="grid grid-cols-2 gap-6">
              <div className="relative z-0 w-full mb-2 group">
                <h2 className="text-sm font-semibold ">Delivery fee:</h2>
              </div>
              <div className="relative z-0 w-full mb-2 group">
                <h2 className="text-sm font-semibold ">{`₦${delivery.toFixed(
                  2
                )}`}</h2>
              </div>
            </div>

            <div className="grid grid-cols-2 gap-6">
              <div className="relative z-0 w-full mb-2 group">
                <h2 className="text-sm font-semibold ">Service Chrg (15%):</h2>
              </div>
              <div className="relative z-0 w-full mb-2 group">
                <h2 className="text-sm font-semibold ">{`₦${serviceCharge.toFixed(
                  2
                )}`}</h2>
              </div>
            </div>

            <div className="grid grid-cols-2 gap-6 border-t-2 border-gray-300 py-4">
              <div className="relative z-0 w-full mb-2 group">
                <h2 className="text-md font-semibold text-pink-700">Total:</h2>
              </div>
              <div className="relative z-0 w-full mb-2 group">
                <h2 className="text-md font-semibold text-pink-700">{`₦${grandTotal.toFixed(
                  2
                )}`}</h2>
              </div>
            </div>
          </>
        )}
        
      </div>
    </>
  );
}

export default CheckoutSummary;