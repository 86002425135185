import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { FILTER_BY_CATEGORY, FILTER_BY_BRAND, FILTER_BY_PRICE } from '../../context/Slice/filterSlice';
import  { selectProducts, selectMinPrice, selectMaxPrice } from "../../context/Slice/productSlice";






const ProductFilter = () => {
    const [category, setCategory] = useState("All");
    const [brand, setBrand] = useState("All");
    const [price, setPrice] = useState(10000);
    const products = useSelector(selectProducts);
    const minPrice = useSelector(selectMinPrice);
    const maxPrice = useSelector(selectMaxPrice);
    const dispatch = useDispatch();


    const allCategories = [
        "All",
        ...new Set(products.map((product) => product.category))
    ]; 


    const allBrands = [
        "All",
        ...new Set(products.map((product) => product.brand))
    ];

    //console.log(allCategories);

    useEffect(() =>{
        dispatch(FILTER_BY_BRAND({products, brand}))
    }, [dispatch, products, brand]);


    useEffect(() => {
        dispatch(FILTER_BY_PRICE({products, price}))
    }, [dispatch, products, price]);

    
    const filterProducts = (cat) =>{
        setCategory(cat);
        dispatch(FILTER_BY_CATEGORY({products, category: cat}))
    }


    const clearFilters = () =>{
        setCategory("All");
        setBrand("All");
        setPrice(maxPrice);
    }


  return (
    <div className="hidden md:flex justify-center items-center z-[500px] lg:z-0">
      {/**<h4>Categories</h4>
        <div>
            {allCategories.map((cat, index) =>{
                return(
                    <button className=" hover:bg-gray-200 text-gray-800 text-md font-semibold mr-2 px-3 py-1.5 rounded-full  border border-gray-400 cursor-pointer" key={index} type="button" onClick={() => filterProducts(cat)}>{cat}</button>
                )
            })}
            
        </div>**/}

      <div>
        <h4 className="font-light">Filtered by Price</h4>

        <div>
          <input
            type="range"
            value={price}
            onChange={(e) => setPrice(e.target.value)}
            min={minPrice}
            max={maxPrice}
            className="w-[190px] mt-4 accent-pink-500"
          />
          <p className="mt-2 font-light">₦{price}</p>
        </div>

        <div className="mt-12">
          <h4 className="font-light">Brand</h4>
          <select
            className="w-[190px] mt-4 font-light rounded-lg bg-transparent border border-gray-300 px-2.5 py-2"
            value={brand}
            onChange={(e) => setBrand(e.target.value)}
          >
            {allBrands.map((brand, index) => {
              return (
                <option key={index} value={brand}>
                  {brand}
                </option>
              );
            })}
          </select>
        </div>

        <br />
        <button
          className="w-[150px] bg-gray-800 text-white rounded-lg px-3 py-2 mt-8 font-light hover:bg-pink-500 hover:text-white"
          onClick={() => clearFilters()}
        >
          Clear Filters
        </button>
      </div>
    </div>
  );
}

export default ProductFilter;