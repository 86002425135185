import React, { useState } from 'react';
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { sendPasswordResetEmail } from "firebase/auth";
import { auth } from "../../firebase.config";

const Reset = () => {
    //const navigate = useNavigate();
    const [email, setEmail] = useState("");
    //const [isLoading, setIsLaoding] = useState(false);

    //###### Function reseting user password ########
    const resetPassword = (e) =>{
        e.preventDefault();
        //setIsLaoding(true);
        sendPasswordResetEmail(auth, email)
        .then(() => {
            // Password reset email sent!
          //setIsLaoding(false);
            toast.success(" Password reset email sent!", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
              });
            // ..
        })
          .catch((error) => {
            //setIsLaoding(false);
            toast.error(error.message);
            // ..
        });
    }

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        limit={1}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
      <div className="relative flex flex-col justify-center min-h-screen overflow-hidden -top-8 ">
        <div className="w-full p-6 m-auto bg-white rounded-3xl shadow-2xl lg:max-w-xl">
          <h1 className="text-xl font-semibold text-center text-gray-400">
            Reset Password
          </h1>
          <form className="mt-6" onSubmit={resetPassword}>
            <div className="mb-2">
              <label
                for="email"
                className="block text-sm font-semibold text-gray-500"
              >
                Email
              </label>
              <input
                type="email"
                placeholder="mail@example.com"
                className="block w-full px-4 py-2 mt-2 text-gray-600 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>

            <div className="mt-6">
              <motion.button
                type="submit"
                whileTap={{ scale: 0.75 }}
                className="w-full lg:w-[220px] px-4 py-2 tracking-wide text-white transition-colors duration-200 transform bg-urmallColor rounded-md hover:bg-pink-700 focus:outline-none focus:bg-pink-600"
              >
                Reset Password
              </motion.button>
              <Link
                to="/Login"
                className="w-full lg:w-[220px] px-4 py-2 tracking-wide text-white transition-colors duration-200 transform bg-blue-400 rounded-md hover:bg-blue-700 focus:outline-none focus:bg-pink-600 lg:float-right float-none items-center justify-center"
              >
                Back to Login
              </Link>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default Reset;