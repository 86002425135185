import React, { useEffect, useState } from "react";
// import {
//   PaymentElement,
//   LinkAuthenticationElement,
//   useStripe,
//   useElements
// } from "@stripe/react-stripe-js";
import CheckoutSummary from "./CheckoutSummary";
import { toast } from "react-toastify";

import { firestore } from '../../firebase.config';
import { collection, addDoc, Timestamp } from "firebase/firestore";
import { useNavigate, Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { selectUserID, selectEmail, selectUsername } from "../../context/Slice/authSlice";
import { CLEAR_CART, selectCartItems, selectCartTotalAmount } from "../../context/Slice/cartSlice";
import { selectBillingAddress, selectShippingAddress } from "../../context/Slice/checkoutSlice";
import { IoMdArrowForward, IoMdArrowBack } from "react-icons/io";




const CheckoutForm = () => {
  // const stripe = useStripe();
  // const elements = useElements();
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const userID = useSelector(selectUserID);
  const userEmail = useSelector(selectEmail);
  const userName = useSelector(selectUsername);
  const cartItems = useSelector(selectCartItems);
  const shipping = useSelector(selectShippingAddress);
  const totalAmount = useSelector(selectCartTotalAmount);
  const billing = useSelector(selectBillingAddress);
 

  const delivery = 1200;
  const serviceCharge = 0.13 * totalAmount;
  const grandTotal = serviceCharge + delivery + totalAmount;


  const [email, setEmail] = useState('');
  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  // useEffect(() => {
  //   if (!stripe) {
  //     return;
  //   }

  //   const clientSecret = new URLSearchParams(window.location.search).get(
  //     "payment_intent_client_secret"
  //   );

  //   if (!clientSecret) {
  //     return;
  //   }

    
  // }, [stripe]);
  



const halfBytesIn35 = 7 // = 3.5 bytes
const byte35 = Math.pow(16, halfBytesIn35);
  const bytes35 = () => ((Math.random() * byte35) | 0).toString(16).padStart(halfBytesIn35, '0');
function moreBytes (len) {
          len *= 2; // alternative: len <<= 1 if you do not use half bytes. This might allow optimizations based on len always being an Integer then.
          let builder = "";
          while (len > 0) {
            builder += bytes35()
            len -= 7
          }
          return builder.slice(0,len)
        }
        
        const tempID = moreBytes(8); //####### Generated code for temporary order ID #######
        



  //############# Sending Mail for invoice ###########################
  const cartTable = cartItems.map((item, index) => {
    const { id, name, price, cartQuantity } = item;

    return `<tr key=${id} className="border-gray-300 border-b-2" >
                <td>${index + 1}</td>
                <td>${name}</td>
                <td>${cartQuantity}</td>
                <td>₦${price.toFixed(2)}</td>
                <td>₦${(price * cartQuantity).toFixed(2)}</td>
            </tr>`;
  });

  const shippingAddress = Object.entries(shipping).map(([key, val], i) => {
    return `${val}`;
  });
  const billingAddress = Object.entries(billing).map(([key, val], i) => {
    return `${val}`;
  });

  const sendMail = () => {
    const today = new Date();
    const date = today.toDateString();
    const time = today.toLocaleTimeString();

    const ebody = `
      <h1 style='background:" #000"; border-radius: "0.25em"; color: "#FFF"; margin: "0 0 1em"; padding: "0.5em 0";'>Invoice</h1>
      <hr />
      <h3>Order # ${tempID}</h3>
      Dear ${userName} <br /> <br /> 
      You have successfully placed your order <br/> 
      Below are your order details: 
      <br /><br />
      <b>Shipping to:</b>
      ${shippingAddress}
      <br />
      <b>Billing to:</b>
      ${billingAddress}
      <br />
      <b>Order Date:</b>
      ${date}
      <br />
      <b>Order Time:</b>
      ${time}
      <br /><br /> 
      <p><b>Payment Method: </b>Payment on delivery</p>
      <hr />
      <div key={id} class="col-md-12">
        <table
              className="table border-2 border-gray-300 px-4 py-4"
              style={{borderCollapse:"collapse",padding:"10px 10px",}}
            >
              <thead>
                <tr>
                  <th className="border-0 text-uppercase small font-weight-bold px-4 py-4">
                    ID
                  </th>
                  <th className="border-0 text-uppercase small font-weight-bold px-4 py-4">
                    Item
                  </th>
                  <th className="border-0 text-uppercase small font-weight-bold px-4 py-4">
                    Quantity
                  </th>
                  <th className="border-0 text-uppercase small font-weight-bold px-4 py-4">
                    Unit Cost
                  </th>
                  <th className="border-0 text-uppercase small font-weight-bold">
                    Total
                  </th>
                </tr>
              </thead>
              <tbody className="border-gray-300 border-b-2">${cartTable}</tbody>
              <tfoot>
                <tr>
                  <td colSpan="5"></td>
                </tr>
                <tr className="border-gray-300 border-b-2">
                  <td colSpan="2"></td>
                  <td colSpan="2"><b>SUBTOTAL</b></td>
                  <td>₦${totalAmount.toFixed(2)}</td>
                </tr>
                <tr className="border-gray-300 border-b-2">
                  <td colSpan="2"></td>
                  <td colSpan="2"><b>Service Charge 13%</b></td>
                  <td>₦${serviceCharge.toFixed(2)}</td>
                </tr>
                <tr className="border-gray-300 border-b-2">
                  <td colSpan="2"></td>
                  <td colSpan="2"><b>Delivery</b></td>
                  <td>₦${delivery.toFixed(2)}</td>
                </tr>
                <tr className="border-gray-300 border-b-2">
                  <td colSpan="2"></td>
                  <td colSpan="2"><b>GRAND TOTAL</b></td>
                  <td>₦${grandTotal.toFixed(2)}</td>
                </tr>
              </tfoot>
            </table>
      </div>
      <br /><br />
      <b>Thank you</b>
      <br />
      <b>Urmall.ng Group</b>

      <br /> <br /> <br />
      <b>Notice:</b>&nbsp; A finance charge of 1.5% will be made on unpaid balances after 30 days.
      <br />
      <hr />
      <i>#Invoice was created on a computer and is valid without the signature and seal.</i>
    `;

    const configEmail = {
      SecureToken: process.env.REACT_APP_EMAIL_SECURE_TOKEN,
      To: `${userEmail}`,
      From: "urmall.ng@gmail.com",
      Cc: "abmusadeeq@outlook.com",
      Subject: `INVOICE FOR YOUR ORDER: ${tempID} on ${date} by ${time}`,
      Body: ebody,
    };

    if (window.Email) {
      window.Email.send(configEmail).then(() => null);
    }
  };


  const saveOrder = () => {
      const today = new Date()
      const date = today.toDateString();
      const time = today.toLocaleTimeString();


      

      const orderConfig = {
        userID,
        userEmail,
        cartItems,
        shipping,
        billing,
        serviceCharge: serviceCharge,
        totalAmount: totalAmount,
        deliveryFee: delivery,
        orderAmount: grandTotal,
        orderDate: date,
        orderTime: time,
        orderStatus: "Order Placed...",
        PaymentStatus: "Pending...",
        orderTempID: tempID,
        createdAt: Timestamp.now().toDate(),
      }

      try{

            // Add a new document with a generated id.
            addDoc(collection(firestore, "orders"), orderConfig);
            dispatch(CLEAR_CART());
            sendMail();
            navigate("/checkout-success");

        }
        catch(error){
            toast.error(error.message);
        }
  };





  const handleSubmit = async(e) => {
      

      e.preventDefault();
      setMessage(null);
      toast.success("Order successful !");
      saveOrder(); //###### Saving order to database########
      


    // if (!stripe || !elements) {
      
    //   return;
    // }

    // setIsLoading(true);

    // const comfirmPayment = await stripe.confirmPayment({
    //   elements,
    //   confirmParams: {
    //     // Make sure to change this to your payment completion page
    //     return_url: "http://localhost:3000/checkout-success",
    //   },
    //   redirect: "if_required",
    // })
    // .then((result) =>{
    //   if(result.error){
    //     toast.error(result.error.message);
    //     setMessage(result.error.message);
    //     return;
    //   }
    //   if(result.paymentItent){
    //     if(result.paymentItent.status === "succeeded"){
    //       setIsLoading(false)
    //       toast.success("Payment successful");
    //       saveOrder();
    //     }
    //   }

    // });

    // setIsLoading(false);

    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`. For some payment methods like iDEAL, your customer will
    // be redirected to an intermediate site first to authorize the payment, then
    // redirected to the `return_url`.
    // if (error.type === "card_error" || error.type === "validation_error") {
    //   setMessage(error.message);
    // } else {
    //   setMessage("An unexpected error occurred.");
    // }

    // setIsLoading(false);
  };

  // const paymentElementOptions = {
  //   layout: "tabs"
  // }

  return (
    <section>
      <div className="lg:flex lg:flex-grow lg:flex-shrink lg:flex-auto gap-8 w-screen  mt-4">
        <CheckoutSummary />
        <div className="rounded-lg shadow-md border border-gray-300 w-[365px] lg:mb-0 mb-4 lg:w-[820px] px-8 py-8">
          <h2 className="text-lg  text-blue-600 border-b-2 border-t-2 px-2.5 py-1.5 mb-4">
            Place order
          </h2>

          <div className="grid grid-cols-1 gap-2">
            <div className="relative z-0 w-full mb-2 group">
              <h2 className="text-sm font-semibold ">Note</h2>
            </div>
            <div className="relative z-0 w-full mb-2 group">
              <h2 className="text-sm ">
                Proceeding to checkout, an invoice will be sent to your mail{" "}
                {`(${userEmail})`}. To process the payment kindly use the below
                account number to Transfer or Deposite.
              </h2>
            </div>
          </div>
          <br />

          <div className="grid grid-cols-1 gap-2">
            <div className="relative z-0 w-full mb-2 group border-b border-gray-300 py-2">
              <h2 className="text-sm">
                <b>Bank:</b> Access Bank
              </h2>
            </div>
            <div className="relative z-0 w-full mb-2 group border-b border-gray-300 py-2">
              <h2 className="text-sm">
                <b>Account Number:</b> 0787770264
              </h2>
            </div>

            <div className="relative z-0 w-full mb-2 group border-b border-gray-300 py-2">
              <h2 className="text-sm">
                <b>Account Name:</b> Abubakar Sadiq Muhammad
              </h2>
            </div>
          </div>

          <div className="grid lg:grid-cols-2 grid-cols-2 gap-2 mt-8">
            <div className="relative z-0 w-full mb-2 group">
              <button
                type="button"
                className=" text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
              >
                <Link to="/cart">Cancel order</Link>
              </button>
            </div>
            <div className="relative z-0 w-full mb-2 group">
              <button
                onClick={(e) => handleSubmit(e)}
                type="button"
                className="text-white bg-pink-600 hover:bg-pink-800  font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center "
              >
                Place Order
              </button>
            </div>
          </div>
        </div>
        <br />

        {/*<div className="rounded-lg shadow-md border border-gray-300 w-[620px] px-8 py-8">
          <form id="payment-form" onSubmit={handleSubmit}>
              <LinkAuthenticationElement
                id="link-authentication-element"
                onChange={(e) => setEmail(e.target.value)}
              />
              <PaymentElement id="payment-element" options={paymentElementOptions} />
              <button disabled={isLoading || !stripe || !elements} id="submit">
                <span id="button-text">
                  {isLoading ? <div className="spinner" id="spinner"></div> : "Pay now"}
                </span>
              </button>
               // Comments: Show any error or success messages
              {message && <div id="payment-message">{message}</div>}
          </form>
        </div>*/}
      </div>
    </section>
  );
}

export default CheckoutForm;