import React from "react";
import Delivery from "../img/delivery.png";
import HeroBg from "../img/heroBg.png";
import heroData from "../utils/data";
import { motion } from "framer-motion";
import { Link } from 'react-router-dom';

const HomeContainer = () => {
  
  return (
    <section className="grid grid-cols-1 md:grid-cols-2 gap-2 w-full" id="home">
      <div className="py-2 flex-1 flex flex-col items-start justify-center gap-6 md:-mt-20">
        <div className="flex items-center gap-2 justify-center bg-orange-100 px-2 py-1 shadow-sm rounded-full">
          <p className="text-base text-urmallColor font-semibold">
            Bike Delivery
          </p>
          <div className="w-8 h-8 rounded-full overflow-hidden bg-white drop-shadow-xl">
            <img
              src={Delivery}
              className="w-full h-full object-contain"
              alt="Delivery"
            />
          </div>
        </div>
        <p className="text-[2.5rem] lg:text-[3.2rem] font-bold tracking-wide text-headingColor">
          What you are Looking for in
          <span className="text-urmallColor text-[3rem] lg:text-[4rem]">
            Your City
          </span>
        </p>
        <p className="text-base text-textColor text-center md:text-left md:w-[80%]">
          Lorem ipsum Lorem ipsumLorem ipsumLorem ipsumLorem ipsumLorem ipsum
          Lorem ipsumLorem ipsum Lorem ipsumLorem ipsum Lorem ipsum ipsum
        </p>
        <Link to="/Shop">
          <motion.span
            whileTap={{ scale: 0.89 }}
            type="button"
            className="bg-gradient-to-br from-pink-500 to-pink-600 w-full md:w-auto px-14 py-2 shadow-sm rounded-3xl text-white hover:shadow-lg transition-all ease-in-out duration-100" >
            <ion-icon name="basket-outline"></ion-icon> &nbsp;Shop Now
          </motion.span>
        </Link>
        
      </div>

      <div className="py-2 flex-1 flex items-center relative">
        <img
          src={HeroBg}
          className="ml-auto h-420 w-full lg:w-auto lg:h-650"
          alt="Hero Background"
        />

        <div className="w-full h-full absolute top-0 left-0 lg:-left-16 flex items-center justify-center py-4 lg:px-10 gap-4 flex-wrap">
          {heroData &&
            heroData.map((n) => (
              <motion.div
                initial={{ opacity: 0, scale: 0.3 }}
                animate={{ opacity: 1, scale: 1 }}
                exit={{ opacity: 0, scale: 0.3 }}
                key={n.id}
                className=" lg:w-190 p-4 min-w-[150px] bg-whiteTransparent backdrop-blur-lg rounded-3xl flex flex-col items-center justify-center shadow-md drop-shadow-lg cursor-pointer hover:shadow-2xl transition-all ease-in-out duration-1000 "
              >
                <img
                  src={n.imageSrc}
                  alt="I1"
                  className="w-20 lg:w-40 -mt-10 lg:-mt-20 "
                />
                <p className="text-base lg:text-xl font-semibold text-textColor mt-2 lg:mt-4">
                  {n.name}
                </p>
                <p className="text-[12px] lg:text-sm text-lightTextGray font-semibold my-1 lg:my-3">
                  {n.decp}
                </p>
                <p className="text-sm font-semibold text-headingColor">
                  <span className="text-xs text-red-600">N</span> {n.price}
                </p>
              </motion.div>
            ))}
        </div>
      </div>
    </section>
  );
};

export default HomeContainer;
