import React from 'react';
import { SideBar } from "../../Components";
import { Routes, Route } from "react-router-dom";
import Home from "../../Components/Admin/Home/Home";
import ViewProducts from '../../Components/Admin/viewProducts/ViewProducts';
import AddProducts from '../../Components/Admin/AddProducts/AddProducts';
import Orders from '../../Components/Admin/Orders/Orders';
import OrderDetails from '../../Components/Admin/orderDetails/OrderDetails';




const Admin = () => {


  return (
    <div>
      <div>
        <SideBar />
      </div>
      <div className="flex h-[900px] bg-grey-lightest  font-light w-screen">
        <Routes>
          <Route path="Home" element={<Home />} />
          <Route path="all-Products" element={<ViewProducts />} />
          <Route path="add-Product/:id" element={<AddProducts />} />
          <Route path="Orders" element={<Orders />} />
          <Route path="Order-details/:id" element={<OrderDetails />} />
        </Routes>
      </div>
    </div>
  );
}

export default Admin;