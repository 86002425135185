
import { useSelector } from 'react-redux';
import { selectIsloggedIn } from '../context/Slice/authSlice';



const ShowOnLogin = ({ children }) => {
    const isLoggedIn = useSelector(selectIsloggedIn);

    if(isLoggedIn){
        return children
    }
    return null;

};

export const ShowOnLogout = ({ children }) => {
    const isLoggedIn = useSelector(selectIsloggedIn);

    if(!isLoggedIn){
        return children
    }
    return null;

}

export default ShowOnLogin;