import React, {useState, useEffect } from "react";
import  { useParams } from "react-router-dom";
import useFetchData from "../../../customeHook/useFetchData.jsx";
import { Link } from "react-router-dom";
import ChangeOrderStatus from "../changeOrderStatus/ChangeOrderStatus";


const OrderDetails = () =>{

    const [order, setOrder] = useState(null);
    const {id} =useParams();
    const {docuument } = useFetchData("orders", id);
    

    useEffect(() =>{
        setOrder(docuument);
    },[docuument]);




    return(
        <section className="ml-44 w-[1050px] h-full">
            
            
                <nav className="flex mt-8 mb-4" aria-label="Breadcrumb">
                    <ol className="inline-flex items-center space-x-1 md:space-x-3">
                        <li className="inline-flex items-center">
                        <Link to="/Admin/Home" className="inline-flex items-center text-sm font-medium text-gray-700 hover:text-blue-600">
                            <svg aria-hidden="true" className="w-4 h-4 mr-2" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"></path></svg>
                            Home
                        </Link>
                        </li>
                        <li>
                        <div className="flex items-center">
                            <svg aria-hidden="true" className="w-6 h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd"></path></svg>
                            <Link to="/Admin/Orders" className="ml-1 text-sm font-medium text-gray-700 hover:text-blue-600 md:ml-2">Orders</Link>
                        </div>
                        </li>
                        <li aria-current="page">
                        <div className="flex items-center">
                            <svg aria-hidden="true" className="w-6 h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd"></path></svg>
                            <span className="ml-1 text-sm font-medium text-gray-500 md:ml-2 ">OrderDetails</span>
                        </div>
                        </li>
                    </ol>
                </nav>
                <div className="border-gray-200 border-b mb-4"><h2 className="text-gray-800 text-xl font-semibold"> Order Details</h2></div>

                {order === null ? (
                    <>
                        <div className="rounded-full bg-pink-200 w-[177px] h-[177px] relative flex justify-center items-center mx-auto animate-spin">
                        <svg
                            className="absolute top-0 0 left-0"
                            width={177}
                            height={177}
                            viewBox="0 0 177 177"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                            d="M169.667 88.5C173.717 88.5 177.032 85.2113 176.696 81.1755C175.457 66.2542 170.448 51.8477 162.085 39.332C152.361 24.7783 138.539 13.435 122.367 6.73666C106.196 0.0383073 88.4018 -1.71429 71.2345 1.7005C54.0672 5.11529 38.298 13.5441 25.9211 25.921C13.5441 38.298 5.1153 54.0672 1.7005 71.2345C-1.71429 88.4018 0.0383044 106.196 6.73666 122.367C13.435 138.539 24.7783 152.361 39.332 162.085C51.8477 170.448 66.2542 175.457 81.1755 176.696C85.2113 177.032 88.5 173.717 88.5 169.667V169.667C88.5 165.618 85.2089 162.373 81.1792 161.971C69.1624 160.774 57.5826 156.642 47.4795 149.891C35.3374 141.778 25.8738 130.247 20.2855 116.755C14.6971 103.264 13.2349 88.4181 16.0838 74.0955C18.9328 59.773 25.9649 46.6168 36.2909 36.2908C46.6169 25.9649 59.773 18.9328 74.0955 16.0838C88.4181 13.2349 103.264 14.6971 116.755 20.2855C130.247 25.8739 141.778 35.3375 149.891 47.4795C156.642 57.5826 160.774 69.1624 161.971 81.1793C162.373 85.209 165.618 88.5 169.667 88.5V88.5Z"
                            fill="#4338CA"
                            />
                        </svg>
                        <div className="div rounded-full bg-slate-50 w-[150px] h-[150px]" />
                        </div>
                    </>
                ) : (
                    <>

                        <div key={order.id}>
                            <p className="text-gray-800 font-medium"><b>Order ID: </b> {order.id}</p>
                            <p className="text-green-700 font-medium"><b>Order Amount: </b> ₦{order.orderAmount.toFixed(2)}</p>
                            <p className="text-pink-700 font-medium"><b>Payment Status: </b> {order.PaymentStatus}</p>
                            <p className="text-gray-800 font-medium"><b>Order Staus: </b> 
                                <span className={ order.orderStatus !=="Delivered" ? `bg-yellow-100 text-yellow-800 text-sm font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-yellow-900 dark:text-yellow-300 hover:text-white hover:bg-yellow-900` : ` bg-green-200 text-green-800 text-sm font-medium mr-2 px-2.5 py-0.5 rounded cursor-pointer hover:text-white hover:bg-green-900`}>{order.orderStatus}</span>
                            </p>
                            <p className="text-gray-800 font-medium"><b>Shipping Address: </b> {order.shipping.line1}, 
                            {order.shipping.line2}, {order.shipping.city} <br />
                            <b>State: </b>{order.shipping.state}
                            <br />
                            <b>Country: </b>{order.shipping.country}<br />
                            <b>Phone: </b>{order.shipping.phone}</p>
                        </div>
                        <div className="relative overflow-x-auto shadow-md sm:rounded-lg mt-4">
                            <table className="w-full text-sm text-left text-gray-500 ">
                                <thead className="text-xs text-gray-700  bg-transparent border-gray-300 border-b border-t ">
                                    <tr>
                                        <th scope="col" className="px-6 py-3">
                                            S/No.
                                        </th>
                                        <th scope="col" className="px-6 py-3">
                                            <span className="sr-only">Image</span>
                                        </th>
                                        <th scope="col" className="px-6 py-3">
                                            Product
                                        </th>
                                        <th scope="col" className="px-6 py-3">
                                            Price
                                        </th>
                                        <th scope="col" className="px-6 py-3">
                                            Qty
                                        </th>
                                        <th scope="col" className="px-6 py-3">
                                            Total
                                        </th>
                                        
                                    </tr>
                                </thead>
                                <tbody>
                                {order.cartItems.map((cart, index) =>{
                                    const {id, name, price, imageURL, cartQuantity} = cart;
                                    return(
                                        

                                        <tr key={id} className="bg-transparent border-b  hover:bg-gray-50 ">
                                            <td className="px-6 py-4 font-semibold text-gray-900 ">
                                                {index + 1}
                                            </td>
                                            <td className="w-32 p-4">
                                                <img src={imageURL} alt={name}/>
                                            </td>
                                            <td className="px-6 py-4 font-semibold text-gray-900 ">
                                                {name}
                                            </td>
                                            <td className="px-6 py-4 font-semibold text-gray-900 ">
                                                N{price.toFixed(2)}
                                            </td>
                                            <td className="px-6 py-4">
                                                <div className="flex items-center space-x-3">
                                                    
                                                    <div>
                                                        <span className="bg-gray-50 w-14 border border-gray-300 text-gray-900 text-sm rounded-lg  block px-2.5 py-1 text-center " >{cartQuantity}</span>
                                                    </div>
                                                    
                                                </div>
                                            </td>
                                            <td className="px-6 py-4 font-semibold text-gray-900 ">
                                                N{(price * cartQuantity).toFixed(2)}
                                            </td>
                                            
                                        </tr>
                                       

                                    )
                                })}
                                    
                                    
                                </tbody>
                            </table>
                            
                        </div>
                        <ChangeOrderStatus order={order} id={id} />
                    </>

                )}
                
                

                
                





        </section>
        
    )
}

export default OrderDetails;