import React, {useRef,useEffect, useState} from "react";
import { MdShoppingBasket } from "react-icons/md";
import { motion } from "framer-motion";
import NotFound from '../img/NotFound.svg';
//import { useSnackbar } from 'notistack';
import useFetchCollection from "../customeHook/useFetchCollection";
import { useDispatch, useSelector } from "react-redux";
import { STORE_PRODUCTS, selectProducts } from "../context/Slice/productSlice";
import { ADD_TO_CART,  CALCULATE_TOTAL_QUANTITY } from "../context/Slice/cartSlice";
import useFetchData from "../customeHook/useFetchData.jsx";
import { Link } from "react-router-dom";



const RowContainer = ({ flag, scrollValue}) => {

  const dispatch = useDispatch();
  const [product, setProduct ] = useState(null);
  const { docuument } = useFetchData("products");


        //########## using useFetchCollection #######
        const { data } = useFetchCollection("products");
        const tempProducts = data.filter((product) => product.category === "Fruit");
        //const [isLoading, setIsLoading] = useState(false);
        const products = useSelector(selectProducts)
        const rowContainer = useRef();
        
        useEffect(() =>{
            dispatch(
                STORE_PRODUCTS({
                  products: data,
              }));
        }, [dispatch, data]);
      //########## using useFetchCollection #######



  
  //const { enqueueSnackbar } = useSnackbar();

    
    
  



 

  

 

  useEffect(() => {
    rowContainer.current.scrollLeft += scrollValue;
  }, [scrollValue]);

 

//############### New Codes #####################
const shortenText = (text, n) => {
    if (text.length > n) {
      const shortenedText = text.substring(0, n).concat("...");
      return shortenedText;
    }
    return text;
  };



  //######## Funtion to add product to cart #########
  const addToCart = (product) => {

    
      dispatch(ADD_TO_CART(product));
      dispatch(CALCULATE_TOTAL_QUANTITY());




      // const message = 'You have added one item to your cart';
      //   enqueueSnackbar(message, { autoHideDuration: 3000, variant: 'success',vertical: 'top', horizontal: 'center',anchorOrigin: { vertical: 'top', horizontal: 'right'}});
  };

   useEffect(() =>{
        setProduct(docuument);
    }, [docuument])

  return (
    <>
      <div
        ref={rowContainer}
        className={`w-full flex items-center gap-2 pt-4 lg:gap-4 my-12 scroll-smooth ${
          flag
            ? "overflow-x-scroll scrollbar-none"
            : "overflow-x-hidden flex-wrap justify-center"
        }`}
      >
        {tempProducts && tempProducts.length > 0 ? (
          tempProducts.map((item) => (
            <div
              key={item?.id}
              className="w-340 lg:w-275 h-[180px] min-w-[275px] md:w-300 md:min-w-[300px]  bg-card rounded-lg py-2 px-4 my-4 md:my-8 backdrop-blur-lg drop-shadow-lg hover:drop-shadow-lg flex flex-col items-center justify-evenly relative hover:shadow-xl  transform  hover:bg-card transition duration-500 group"
            >
              <div className="w-full flex items-center justify-between">
                <Link to={`/product-details/${item?.id}`}>
                  <motion.div
                    whileHover={{ scale: 1.2 }}
                    className="w-40 h-40 -mt-9 drop-shadow-2xl"
                  >
                    <img
                      src={item?.imageURL}
                      alt="product"
                      className="w-full h-full object-contain"
                    />
                  </motion.div>
                </Link>

                {/* ################## Add to cart Button #################### */}
                <motion.div
                  whileTap={{ scale: 0.75 }}
                  className="w-8 h-8 -mt-12 backdrop-blur-lg rounded-full bg-pink-600 flex items-center justify-center cursor-pointer hover:shadow-md"
                  onClick={() => addToCart(item)}
                >
                  <MdShoppingBasket className="text-white" />
                </motion.div>
                {/* ################## End of Add to cart Button #################### */}
              </div>
              <div className="w-full flex flex-col items-end justify-end -mt-9">
                <p className="text-textColor font-semibold text-base md:text-lg">
                  {item?.name}
                </p>
                <p className="text-sm text-gray-500">{item?.brand} Calories</p>
                <div className="flex items-center">
                  <p className="text-lg text-headingColor font-semibold">
                    <span className="text-sm text-red-500">₦</span>
                    {(item?.price).toFixed(2)}
                  </p>
                </div>
              </div>
            </div>
          ))
        ) : (
          <div className="w-full  flex flex-col items-center justify-center">
            <img src={NotFound} className="h-340 -my-4" alt="Not Found" />
            <p className="text-xl text-headingColor font-semibold my-4">
              Items not Available
            </p>
          </div>
        )}
      </div>
    </>
  );
};
export default RowContainer;
