import React, {useState} from 'react';
import { Link,  useNavigate} from 'react-router-dom';



import { signOut } from "firebase/auth";
import { ToastContainer, toast } from 'react-toastify';
import { auth } from "../firebase.config";

import Box from '@mui/material/Box';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import ExitToAppOutlinedIcon from '@mui/icons-material/ExitToAppOutlined';



type Anchor = 'right';



const MobileNavbar = () =>{

const navigate = useNavigate(); //###### Use to navigate user #######


  const [state, setState] = React.useState({
    right: false
  });

  const toggleDrawer =
    (anchor: Anchor, open: boolean) =>
    (event: React.KeyboardEvent | React.MouseEvent) => {
      if (event && event.type === 'keydown' && ((React.KeyboardEvent).key === 'Tab' || (React.KeyboardEvent).key === 'Shift')
      ) {
        return;
      }

      setState({ ...state, [anchor]: open });
    };

  const list = (anchor: Anchor) => (
    <Box
      sx={{ width: 250 }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>
        {[{name: "Shop", nav:"/Shop", icon: "apps-outline"},
        {name: "My Order", nav:"/Order-history", icon: "gift-outline"},
        {name: "Groceries", nav:"/Shop", icon: "person-outline"},
        {name: "Snack", nav:"/Shop", icon: "fast-food-outline"}].map((text, index) => (
          <ListItem key={index} disablePadding>
            <Link to={text.nav}>
              <ListItemButton>
                <ListItemIcon>
                  {<ion-icon   name={text.icon}></ion-icon>}
                </ListItemIcon>
                <ListItemText primary={text.name} />
              </ListItemButton>
            </Link>
          </ListItem>
        ))}
      </List>
      <Divider />
      <List>
        {['Logout'].map((text, index) => (
          <ListItem key={text} disablePadding onClick={() => logoutUser()}>
            <ListItemButton>
              <ListItemIcon>
                {<ExitToAppOutlinedIcon />}
              </ListItemIcon>
              <ListItemText primary={text} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );





  const logoutUser = () =>{
    signOut(auth).then(() => {

        toast.success(" Logout successful...");
              navigate("/Login");

        // Sign-out successful.
      }).catch((error) => {
        toast.error(error.message);
        // An error happened.
      });
  }









    const Menus = [
        {name: "Home", nav:"/", icon: "home-outline",dis:"translate-x-0"},
        {name: "Cart", nav:"/cart", icon: "basket-outline",dis:"translate-x-16"},
        {name: "Shop", nav:"/Shop", icon: "apps-outline",dis:"translate-x-32"},
        {name: "Order", nav:"/Order-history", icon: "gift-outline",dis:"translate-x-48"},
        {name: "Menu", nav:"/Menu", icon: "menu-outline",dis:"translate-x-64"},
    ];
    const [active, setActive] = useState(0);

    return (
      <>
        <div className="fixed bottom-0 items-center justify-center w-full lg:hidden md:hidden bg-white max-h-[4.4rem] px-6 rounded-xl">
          <ul className="flex relative">
            <span
              className={`bg-rose-600 duration-500 ${Menus[active].dis} border-4 border-gray-900 h-16 w-16 rounded-full absolute -top-5`}
            >
              <span className="w-3.5 h-3.5 bg-transparent absolute top-4 -left-[18px] rounded-tr-[11px] shadow-myShadow1"></span>
              <span className="w-3.5 h-3.5 bg-transparent absolute top-4 -right-[18px] rounded-tl-[11px] shadow-myShadow2"></span>
            </span>
            {Menus.map((menu, i) => (
              <li key={i} className="w-16 items-center justify-center ">
                <Link
                  to={menu.name === 'Menu' ? null : menu.nav}
                  className="flex flex-col text-center pt-6 items-center justify-center"
                  onClick={() => setActive(i)}
                >
                  <span
                    className={`text-xl text-center items-center justify-center cursor-pointer duration-500 ${
                      i === active &&
                      "-mt-6 text-white items-center justify-center"
                    }`}
                        >
                            {menu.name === 'Menu' ? (
                              <ion-icon onClick={toggleDrawer('right', true)} name={menu.icon}  ></ion-icon>
                      
                    ) : (<ion-icon   name={menu.icon}></ion-icon>)}
                    
                  </span>
                  <span
                    className={` ${
                      active === i
                        ? "translate-y-4 duration-700 opacity-100"
                        : "opacity-0 translate-y-10"
                    }`}
                  >
                    {" "}
                    {menu.name === 'Menu' ? (
                      <div>
                        {(['right']).map((anchor) => (
                          <React.Fragment key={anchor}>
                            
                            <Button className="text-gray-600" onClick={toggleDrawer(anchor, true)}>{menu.name}</Button>
                            <SwipeableDrawer
                              anchor={anchor}
                              open={state[anchor]}
                              onClose={toggleDrawer(anchor, false)}
                              onOpen={toggleDrawer(anchor, true)}
                            >
                              {list(anchor)}
                            </SwipeableDrawer>
                          </React.Fragment>
                        ))}
                      </div>
                    ) : (
                      menu.name
                    )}
                  </span>
                </Link>
              </li>
            ))}
          </ul>
        </div>
        
      </>
    );
}

export default MobileNavbar;