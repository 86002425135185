
import { useSelector } from 'react-redux';
import { selectEmail } from '../../context/Slice/authSlice';
import { Link } from "react-router-dom";


const AdminOnlyRoute = ({ children }) => {
  const userEmail = useSelector(selectEmail);
  if (userEmail === process.env.REACT_APP_ADMIN_USER) {
    return children;
  }
  return (
    <section>
      <div>
        <h1>Permission Denied</h1>
        <h2>You are not allow to view this page.</h2>
        <br />
        <Link to="/">
          <button>&larr; Back to Home</button>
        </Link>
        
      </div>
    </section>
    
  );
};


export const AdminOnlyLink = ({ children }) => {
    const userEmail = useSelector(selectEmail);
  if(userEmail === "abmusadeeq@gmail.com"){
      return children;
  }
  return null;
}


export default AdminOnlyRoute;