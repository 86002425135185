import React, { useState } from 'react';
import { Link, useNavigate } from "react-router-dom";

import { motion } from "framer-motion";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { createUserWithEmailAndPassword } from "firebase/auth";
import { auth } from "../../firebase.config";

const Register = () => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [cPassword, setCPassword] = useState("");

    const navigate = useNavigate();

    const registerUser = (e) =>{
        e.preventDefault();
        if(password !== cPassword){

            toast.error(' ooops! Password did not match', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            });

        }
        else{
          createUserWithEmailAndPassword(auth, email, password)
            .then((userCredential) => {
              // Signed in
              //const user = userCredential.user;
              //console.log(user);

              toast.success(" Registration successful...", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
              });
              navigate("/Login");
            })
            .catch((error) => {
              toast.error(error.message);
            });
        }
    };

  return (
    <>
        <ToastContainer
            position="top-right"
            autoClose={5000}
            limit={1}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="dark"
        />
      <div className="relative flex flex-col justify-center min-h-screen overflow-hidden -top-4  lg:mt-2">
        <div className="w-full lg:w-[440px] p-6 m-auto bg-white rounded-3xl shadow-2xl lg:max-w-xl">
          <h1 className="text-xl font-semibold text-center text-gray-400">
            Create an account
          </h1>
          <form className="mt-6" onSubmit={registerUser}>
            <div className="mb-2">
              <label
                for="email"
                className="block text-sm font-semibold text-gray-500"
              >
                Email
              </label>
              <input
                type="email"
                placeholder="mail@example.com"
                className="block w-full px-4 py-2 mt-2 text-gray-600 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required 

                />
            </div>
            <div className="mb-2">
              <label
                for="password"
                className="block text-sm font-semibold text-gray-8500"
              >
                Password
              </label>
              <input
                type="password"
                placeholder="******************"
                className="block w-full px-4 py-2 mt-2 text-gray-600 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required />
            </div>
            <div className="mb-2">
              <label
                for="password"
                className="block text-sm font-semibold text-gray-500"
              >
                Confirm Password
              </label>
              <input
                type="password"
                placeholder="******************"
                className="block w-full px-4 py-2 mt-2 text-gray-600 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"
                value={cPassword}
                onChange={(e) => setCPassword(e.target.value)}
                required />
            </div>
            
            
            <div className="mt-6">
              <motion.button whileTap={{ scale: 0.75 }} type="submit" className="w-full px-4 py-2 tracking-wide text-white transition-colors duration-200 transform bg-urmallColor rounded-md hover:bg-pink-700 focus:outline-none focus:bg-pink-600">
                Sign up
              </motion.button>
            </div>
          </form>
          

          <p className="mt-8 text-xs font-light text-center text-gray-700">
            {" "}
            Already have an account?{" "}
            <Link to="/Login" className="font-medium text-urmallColor hover:underline">
              Sign in
            </Link>
          </p>
        </div>
      </div>
    </>
  );
}

export default Register;