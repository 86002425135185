import React from "react";



const Chart = ({className, title, count, icon}) =>{
    return(
        <>
            <section>
                <div className=" px-2 mt-6">
                    <div className="flex flex-wrap ">
                        <div className="w-1/3 px-4 cursor-pointer ">
                            <div className = {className} >
                                <div className="card-body">
                                    <h2 className="card-title">{title}</h2>
                                    <span className="flex justify-between mt-8">
                                        <span>{count}</span>
                                        <span>{icon}</span>
                                    </span>
                                    
                                </div>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </section>
        </>
    );
};

export default Chart;