import React from 'react';
import { motion } from "framer-motion";
import { MdShoppingBasket } from "react-icons/md";
import { Link } from "react-router-dom";
import { useDispatch} from "react-redux";
import { ADD_TO_CART,  CALCULATE_TOTAL_QUANTITY } from "../../context/Slice/cartSlice";





const ProductItem = ({product, grid, id, name, imageURL, price, desc, brand}) => {
  const dispatch = useDispatch();
 

  const shortenText = (text, n) => {
    if (text.length > n) {
      const shortenedText = text.substring(0, n).concat("...");
      return shortenedText;
    }
    return text;
  };

  //######## Funtion to add product to cart #########
  const addToCart = (product) => {
      dispatch(ADD_TO_CART(product));
      dispatch(CALCULATE_TOTAL_QUANTITY());
  };

  //################## Main Program ##########
  return (
    <>
      <div className="w-full flex items-center justify-between py-4">
        <Link to={`/product-details/${id}`}>
          <motion.div
            whileHover={{ scale: 1.2 }}
            className="w-[130px] h-[130px] md:w-[140px] md:h-[140px] md:drop-shadow-2xl cursor-pointer"
          >
            <img
              src={imageURL}
              alt={name}
              className="w-full h-full -mt-14 md:ml-auto md:-mt-9 object-contain"
            />
          </motion.div>
        </Link>

        {/* ################## Add to cart Button #################### */}

        <motion.div
          whileTap={{ scale: 0.75 }}
          onClick={() => addToCart(product)}
          className="hidden md:flex w-8 h-8 -mt-14 rounded-full bg-pink-500 hover:bg-pink-800 flex items-center justify-center cursor-pointer hover:shadow-md"
        >
          <MdShoppingBasket className="text-white font-light" />
        </motion.div>

        {/* ################## End of Add to cart Button #################### */}
      </div>

      <div className="hidden w-full md:flex flex-col items-end justify-end -mt-12">
        <p className="text-textColor font-semibold text-base md:text-lg">
          {shortenText(name, 15)}
        </p>
        <p className="text-sm text-gray-500">{brand}</p>
        <div className="flex items-center">
          <p className="hidden md:flex text-lg text-headingColor font-semibold">
            <span className=" text-sm text-red-500">₦</span>
            {price.toFixed(2)}
          </p>
          {!grid && (
            <p className="text-sm text-gray-500">{shortenText(desc, 200)}</p>
          )}
        </div>
      </div>
      
      {/* ########### Product details for mobile view ###########*/}
      <div className="md:hidden flex flex-col items-left mt-auto">
        <p className="text-textColor font-semibold text-base md:text-lg">
            {shortenText(name, 15)}
          </p>
        <p className="text-sm text-gray-500">{brand}</p>
      </div>

      <div className="flex flex-row justify-between items-center gap-6 mt-auto">
        <p className="md:hidden text-lg text-headingColor font-semibold ">
          <span className="text-sm text-red-500">₦</span>
            {price.toFixed(2)}
        </p>
        <motion.div
            whileTap={{ scale: 0.75 }}
            onClick={() => addToCart(product)}
            className="flex md:hidden w-8 h-8 rounded-full bg-pink-500 hover:bg-pink-800 items-center justify-center cursor-pointer hover:shadow-md"
          >
            <MdShoppingBasket className="text-white font-light" />
        </motion.div>
        
      </div>
      
    </>
  );
}

export default ProductItem;