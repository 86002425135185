import React, { useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import { MainContainer, CreateContainer, Header, Footer, MobileNavbar, Shop, AdminOnlyRoute, ProductDetails, ReviewProduct } from "./Components";
import { Login, Register, Reset, OrderHistory, OrderDetails, Admin, Cart, Checkout, CheckoutDetails, CheckoutSuccess, Contact, NotFound } from "./pages";
import { AnimatePresence } from "framer-motion";
import { useStateValue } from "./context/StateProvider";
import { getAllFoodItems } from "./utils/firebaseFunctions";
import { actionType } from "./context/reducer";
import ScrollToTop from "react-scroll-to-top";
import { ToastContainer } from "react-toastify";







function App() {


    





  const [{ cartShow }] = useStateValue();
  const [{ foodItems }, dispatch] = useStateValue();

  const fetchData = async () => {
    await getAllFoodItems().then((data) => {
      dispatch({
        type: actionType.SET_FOOD_ITEMS,
        foodItems: data
      });
    });
  };

  useEffect(() => {
    fetchData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps


  useEffect(() => {}, [cartShow]);
  console.log(process.env);

  return (
    <>
      <ToastContainer
            
            autoClose={4000}
            limit={1}
            hideProgressBar={true}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="dark"
        />
        
        
      <AnimatePresence exitBeforeEnter>
        <div className="w-screen h-auto flex flex-col">
          <ScrollToTop />
          <Header />
          <main className="mt-16 md:mt-16 px-4 md:px-16 py-4 w-full bg-primary h-full">
            <Routes>
              <Route path="/" element={<MainContainer />} />
              <Route path="/CreateItem" element={<CreateContainer />} />
              <Route path="/Contact" element={<Contact />} />
              <Route path="/Shop" element={<Shop />} />
              <Route path="/Login" element={<Login />} />
              <Route path="/Register" element={<Register />} />
              <Route path="/Reset" element={<Reset />} />
              <Route path="/Product-details/:id" element={<ProductDetails />} />
              <Route path="/Order-history" element={<OrderHistory />} />
              <Route path="/Order-details/:id" element={<OrderDetails />} />
              <Route path="/review-product/:id" element={<ReviewProduct />} />
              <Route path="/cart" element={<Cart />} />
              <Route path="/checkout-details" element={<CheckoutDetails />} />
              <Route path="/checkout" element={<Checkout />} />
              <Route path="/checkout-success" element={<CheckoutSuccess />} />
              
              <Route path="/Admin/*" element={ <AdminOnlyRoute> <Admin /> </AdminOnlyRoute>} />
              <Route path="*" element={<NotFound />} />


            </Routes>
            {/* ########### Cart Container ####### */}
           
            
          </main>
          <Footer />
          <MobileNavbar />
        </div>
      </AnimatePresence>
    </>
  );
}

export default App;
