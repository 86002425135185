import React, {useEffect} from 'react';
import { useSelector, useDispatch } from "react-redux";
import { ADD_TO_CART, DECREASE_CART, REMOVE_FROM_CART, CLEAR_CART, selectCartItems, selectCartTotalQuantity, selectCartTotalAmount, CALCULATE_SUBTOTAL, CALCULATE_TOTAL_QUANTITY, SAVE_URL } from "../../context/Slice/cartSlice";
//import { IoIosArrowRoundBack, IoIosArrowRoundForward } from "react-icons/io";
import { motion } from "framer-motion";
import emptyCart from "../../img/emptyCart.svg";
import { Link, useNavigate } from "react-router-dom";
import { selectIsloggedIn } from '../../context/Slice/authSlice';



const Cart = () => {

    const cartItems = useSelector(selectCartItems);
    const cartTotalQuantity = useSelector(selectCartTotalQuantity);
    const cartTotalAmount = useSelector(selectCartTotalAmount);
    const isLoggedIn = useSelector(selectIsloggedIn);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    
    

    //###### Increase cart quantity ######

    const increaseCart = (cart) => {
        dispatch(ADD_TO_CART(cart));
    };

    //###### Decrease cart quantity ######

    const decreaseCart = (cart) => {
        dispatch(DECREASE_CART(cart));
    };

    //###### Delete item from cart ######

    const deleteFromCart = (cart) => {
        dispatch(REMOVE_FROM_CART(cart));
    };

    //###### Clear cart ######

    const clearCart = () => {
        dispatch(CLEAR_CART());
    };

    //###### Use Effect to calculate the sub total ######
     useEffect(() =>{
         dispatch(CALCULATE_SUBTOTAL());
         dispatch(CALCULATE_TOTAL_QUANTITY());
         dispatch(SAVE_URL(""));
     }, [dispatch, cartItems]);

    const url = window.location.href;
    
    
    const checkout = () => {
        if(isLoggedIn){
            navigate("/checkout-details");
        }
        else {
            dispatch(SAVE_URL(url));
            navigate("/login");
        }
    }


    return (
      <>
        <nav className="flex py-3 text-gray-700  rounded-lg  bg-transparent" aria-label="Breadcrumb">
            <ol className="inline-flex items-center space-x-1 md:space-x-3">
                <li className="inline-flex items-center">
                <Link to="/" className="inline-flex items-center text-sm font-normal text-gray-400 hover:text-gray-900  group hover:text-gray-900">
                    <svg className="w-4 h-4 mr-2" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"></path></svg>
                    Home
                </Link>
                </li>
                <Link to="/Shop" >
                <li aria-current="page">
                  <div className="flex items-center">
                      <svg className="w-6 h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd"></path></svg>
                      <span className="ml-1 text-sm font-normal md:ml-2 text-pink-400">Shop</span>
                  </div>
                </li>
                </Link>

                <li aria-current="page">
                  <div className="flex items-center">
                      <svg className="w-6 h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd"></path></svg>
                      <span className="ml-1 text-sm font-normal md:ml-2 text-gray-400">Cart</span>
                  </div>
                </li>
            </ol>
            </nav>




        <div className="flex  justify-center items-center w-70 flex-row">
          <div className="w-full overflow-x-auto">
            <div className="flex my-2 justify-between mt-4 mb-8">
              <h3 className="text-xl text-gray-800 font-semibold tracking-wider">
                <ion-icon name="bag-handle-outline"></ion-icon> Shopping Cart
              </h3>
              <h3 className="text-xl text-gray-800 font-light tracking-wider">
                {cartTotalQuantity} item(s)
              </h3>
            </div>
            {cartItems.length === 0 ? (
              <>
                <div className="justify-center items-center">
                  <img
                    src={emptyCart}
                    alt="empty cart"
                    className="w-[400px] h-[400px]"
                  />

                  <h1>Your cart is currently empty</h1>
                  <br />
                  <Link to="/#products">
                    <h1>
                      <ion-icon name="bag-handle-outline"></ion-icon> Continue
                      Shopping
                    </h1>
                  </Link>
                </div>
              </>
            ) : (
              <>
                <div className="grid grid-col-1 lg:grid-cols-3 items-center justify-center overflow-x-hidden gap-4">
                  <div className="col-span-2">
                    <table className="w-full shadow-inner  border-gray-400 ">
                      <thead>
                        <tr className="bg-gray-100 border-b border-gray-400">
                          <th className="px-2 py-3 text-gray-800 font-semibold whitespace-nowrap">
                            <span className="sr-only">S/no</span>
                          </th>
                          <th className="px-6 py-3 text-gray-800 font-semibold whitespace-nowrap">
                            Product
                          </th>
                          <th className="hidden md:table-cell px-6 py-3 text-gray-800 font-semibold whitespace-nowrap">
                            Price
                          </th>

                          <th className="hidden md:table-cell px-6 py-3 text-gray-800 font-semibold whitespace-nowrap">
                            Qty
                          </th>

                          <th className="hidden md:table-cell px-6 py-3 text-gray-800 font-semibold whitespace-nowrap">
                            Total
                          </th>
                          <th className=" px-4 py-3 text-gray-800 font-semibold whitespace-nowrap">
                            <span className="sr-only">Action</span>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {cartItems.map((cart, index) => {
                          const { id, name, imageURL, price, cartQuantity } =
                            cart;

                          return (
                            <tr
                              key={id}
                              className="hover:bg-gray-100 my-4 table-row border-b border-gray-400"
                            >
                              <td>
                                <div className="flex flex-col items-center justify-center">
                                  <h3>{index + 1}</h3>
                                </div>
                              </td>
                              <td>
                                <div className="flex justify-center">
                                  <img
                                    src={imageURL}
                                    className="object-cover mx-4 my-4 h-20 w-25 "
                                    alt={name}
                                  />
                                </div>
                                <div className="flex flex-col items-center font-light justify-center">
                                  <h3>{name}</h3>
                                </div>
                              </td>
                              <td className="py-2 px-2 lg:py-4 lg:px-6 text-center whitespace-nowrap">
                                ₦{price.toFixed(2)}
                                <br />
                                <div className="border border-gray-400 rounded-full px-4 py-2.5 w-[120px] lg:px-2.5 lg:py-2.5 mt-8 sm:hidden flex">
                                  <motion.button
                                    whileTap={{ scale: 0.75 }}
                                    className=" float-left"
                                    onClick={() => decreaseCart(cart)}
                                  >
                                    <ion-icon name="remove-outline"></ion-icon>
                                  </motion.button>
                                  <input
                                    type="text"
                                    name="qty"
                                    value={cartQuantity}
                                    className="w-12 text-center bg-transparent outline-none rounded-full"
                                    readonly
                                  />
                                  <motion.button
                                    whileTap={{ scale: 0.75 }}
                                    className=" float-right"
                                    onClick={() => increaseCart(cart)}
                                  >
                                    <ion-icon name="add-outline"></ion-icon>
                                  </motion.button>
                                </div>
                                <div className="flex sm:hidden mt-12">
                                  <b>Total &nbsp;</b>₦
                                  {(price * cartQuantity).toFixed(2)}
                                  {/*########### Button for deleting on Mobile view*/}
                                  <motion.button
                                    whileTap={{ scale: 0.75 }}
                                    className="flex sm:hidden hover:bg-gray-300 hover:p-2 hover:rounded-full p-2 text-red-500 ml-4"
                                    onClick={() => deleteFromCart(cart)}
                                  >
                                    <ion-icon name="trash-outline"></ion-icon>
                                  </motion.button>
                                </div>
                              </td>
                              <td className="py-6 px-4  lg:p-4 lg:px-6 text-center whitespace-nowrap ">
                                <div className="sm:flex hidden border border-gray-400 rounded-full px-4 lg:px-2.5 lg:py-2.5 w-[120px]">
                                  <motion.button
                                    whileTap={{ scale: 0.75 }}
                                    className=" float-left"
                                    onClick={() => decreaseCart(cart)}
                                  >
                                    <ion-icon name="remove-outline"></ion-icon>
                                  </motion.button>
                                  <input
                                    type="text"
                                    name="qty"
                                    value={cartQuantity}
                                    className="w-12 text-center bg-transparent outline-none rounded-full"
                                    readonly
                                  />
                                  <motion.button
                                    whileTap={{ scale: 0.75 }}
                                    className=" float-right"
                                    onClick={() => increaseCart(cart)}
                                  >
                                    <ion-icon name="add-outline"></ion-icon>
                                  </motion.button>
                                </div>
                              </td>
                              <td className="hidden lg:table-cell p-4 px-6 text-center whitespace-nowrap">
                                ₦{(price * cartQuantity).toFixed(2)}
                              </td>
                              <td className="hidden md:table-cell p-4 px-2 text-center whitespace-nowrap">
                                <motion.button
                                  whileTap={{ scale: 0.75 }}
                                  className="hover:bg-gray-300 hover:p-2 hover:rounded-full p-2 text-red-500"
                                  onClick={() => deleteFromCart(cart)}
                                >
                                  <ion-icon name="trash-outline"></ion-icon>
                                </motion.button>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                      <tfoot>
                        <tr>
                          <td colspan="6">
                            <motion.button
                              whileTap={{ scale: 0.75 }}
                              onClick={() => clearCart()}
                              className="
                                  lg:w-[140px]
                                  w-full
                                  float-right
                                  py-2
                                  lg:mx-8
                                  mt-4
                                  mb-4
                                  text-center text-gray-600
                                  underline
                                  underline-offset-8
                                  
                                  
                                  hover:text-red-600
                                  "
                            >
                              <ion-icon name="trash-outline"></ion-icon> Clear
                              Cart
                            </motion.button>
                          </td>
                        </tr>
                      </tfoot>
                    </table>
                  </div>
                  <div className="mt-4 border-t-2 w-screen lg:w-[360px] md:w-[560px]  text-gray-600">
                    <div className="py-8 px-6 lg:px-8 rounded-md shadow-lg">
                      <h3 className="text-xl font-semibold text-gray-700 mb-4">
                        Order Summary
                      </h3>
                      <p className="mb-2">Cart Item(s): {cartTotalQuantity}</p>
                      <div className="flex justify-between px-4 py-4 mt-4 border-t border-gray-500">
                        <span className="font-semibold">Subtotal</span>
                        <span>₦{cartTotalAmount.toFixed(2)}</span>
                      </div>
                      <div className="flex justify-between px-4 py-2 border-t border-gray-200">
                        <span className="font-semibold">Delivery</span>
                        <span className=" text-red-600">₦1200.00</span>
                      </div>
                      <div className="flex justify-between px-4 py-2 border-t border-gray-200">
                        <span className="font-semibold">
                          Service charge<sub>(13%)</sub>
                        </span>
                        <span>₦{(cartTotalAmount * 0.13).toFixed(2)}</span>
                      </div>
                      <div
                        className="
                            flex
                            items-center
                            justify-between
                            px-4
                            py-4
                            mt-4
                            mb-4
                            border-t
                            border-gray-600
                        "
                      >
                        <span className="text-xl text-gray-600 font-semibold">
                          Total
                        </span>
                        <span className="text-xl text-gray-600 font-semibold">
                          ₦
                          {(
                            cartTotalAmount * 0.13 +
                            cartTotalAmount +
                            1200
                          ).toFixed(2)}
                        </span>
                      </div>
                      <motion.button
                        whileTap={{ scale: 0.75 }}
                        className="
                          lg:w-[300px]
                          w-full
                          py-2
                          
                          mt-4
                          text-center text-white
                          bg-pink-500
                          rounded-md
                          shadow
                          hover:bg-pink-600
                          "
                      >
                        <Link to="/#products">
                          <ion-icon name="bag-handle-outline"></ion-icon>{" "}
                          Continue Shopping
                        </Link>
                      </motion.button>
                      <motion.button
                        whileTap={{ scale: 0.75 }}
                        onClick={() => checkout()}
                        className="
                        lg:w-[300px]
                        w-full
                        py-2
                        mt-4
                        text-center text-white
                        bg-blue-400
                        rounded-md
                        shadow
                        hover:bg-blue-500
                        "
                      >
                        <Link to="/checkout-details">Checkout &rarr;</Link>
                      </motion.button>
                    </div>
                  </div>
                </div>
              </>
            )}

            {/*<div className="lg:w-2/4">
              <div className="mt-4">
                <div className="px-4 py-4 rounded-md">
                  <label for="coupon code" className="font-semibold text-gray-600">
                    Coupon Code
                  </label>
                  <input
                    type="text"
                    placeholder="coupon code"
                    value="LARA#234"
                    className="
                            w-full
                            px-2
                            py-2
                            border border-blue-600
                            rounded-md
                            outline-none
                            "
                  />
                  <span className="block text-green-600">
                    Coupon code applied successfully
                  </span>
                  <button
                    className="
                            px-6
                            py-2
                            mt-2
                            text-sm text-indigo-100
                            bg-indigo-600
                            rounded-md
                            hover:bg-indigo-700
                            "
                  >
                    Apply
                  </button>
                </div>
              </div>
            </div>*/}
          </div>
        </div>
      </>
    );
}

export default Cart;