import { createSlice } from "@reduxjs/toolkit";
import { toast} from 'react-toastify';
 

const initialState = {
    cartItems: localStorage.getItem("cartItems") ? JSON.parse(localStorage.getItem("cartItems")) : [],
    cartTotalQuantity: 0,
    cartTotalAmount: 0,
    previousURL: "",

}

const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    ADD_TO_CART(state, action) {
      //console.log(action.payload);
      const productIndex = state.cartItems.findIndex(
        (item) => item.id === action.payload.id
      );

      if (productIndex >= 0) {
        //####### Item already exist in cart
        //####### Increase the cartQuantity

        state.cartItems[productIndex].cartQuantity += 1;
        toast.info(` ${action.payload.name} Increased in your cart `, {
          position: "top-left",
        });
      } else {
        //######## Item does not exist in the cart
        //######## Add item to the cart

        const tempProduct = { ...action.payload, cartQuantity: 1 };
        state.cartItems.push(tempProduct);
        toast.success(`${action.payload.name} added to cart`, {
          position: "top-left",
        });
      }
      //######## Save to cart Local storage

      localStorage.setItem("cartItems", JSON.stringify(state.cartItems));
    },//###### end of the function #####

      
    DECREASE_CART(state, action) {
      //console.log(action.payload);
      const productIndex = state.cartItems.findIndex(
        (item) => item.id === action.payload.id
      );

      if (state.cartItems[productIndex].cartQuantity > 1) {
        state.cartItems[productIndex].cartQuantity -= 1;
        toast.info(`${action.payload.name} Decreased in your cart `, {
          position: "top-left",
        });
      } else if (state.cartItems[productIndex].cartQuantity === 1) {
        const newCartItem = state.cartItems.filter(
          (item) => item.id !== action.payload.id
        );
        state.cartItems = newCartItem;
        toast.error(` ${action.payload.name} removed from cart `, {
          position: "top-left",
        });
      }
      //######## Save to cart Local storage

      localStorage.setItem("cartItems", JSON.stringify(state.cartItems));
    }, //###### end of the function #####

    REMOVE_FROM_CART(state, action) {
      const newCartItem = state.cartItems.filter(
        (item) => item.id !== action.payload.id
      );
      state.cartItems = newCartItem;
      toast.error(` ${action.payload.name} Deleted from cart `, {
        position: "top-left",
      });

      //######## Save to cart Local storage

      localStorage.setItem("cartItems", JSON.stringify(state.cartItems));
    }, //###### end of the function #####

    CLEAR_CART(state, action) {
      state.cartItems = [];
      toast.error(` Cart successfully Cleared `, {
        position: "top-left",
      });

      //######## Save to cart Local storage

      localStorage.setItem("cartItems", JSON.stringify(state.cartItems));
    }, //###### end of the function #####

    CALCULATE_SUBTOTAL(state, action) {
      const array = [];
      state.cartItems.map((item) => {
        const { price, cartQuantity } = item;
        const cartItemAmount = price * cartQuantity;
        return array.push(cartItemAmount);
      });
      const totalAmount = array.reduce((a, b) => {
        return a + b;
      }, 0);
      state.cartTotalAmount = totalAmount;
    }, //###### end of the function #####

    CALCULATE_TOTAL_QUANTITY(state, action) {
      const array = [];
      state.cartItems.map((item) => {
        const { cartQuantity } = item;
        const quantity = cartQuantity;
        return array.push(quantity);
      });
      const totalQuantity = array.reduce((a, b) => {
        return a + b;
      }, 0);
      state.cartTotalQuantity = totalQuantity;
    },
      SAVE_URL(state, action) {
          
          state.previousURL = action.payload; 
          
    }, //###### end of the function #####
  },
});

export const { ADD_TO_CART, DECREASE_CART, REMOVE_FROM_CART, CLEAR_CART, CALCULATE_SUBTOTAL, CALCULATE_TOTAL_QUANTITY, SAVE_URL, } = cartSlice.actions

export const selectCartItems = (state) => state.cart.cartItems;
export const selectCartTotalQuantity = (state) => state.cart.cartTotalQuantity;
export const selectCartTotalAmount = (state) => state.cart.cartTotalAmount;
export const selectPreviousURL = (state) => state.cart.previousURL;

export default cartSlice.reducer