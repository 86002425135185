import React, { useEffect } from "react";
import useFetchCollection from "../../customeHook/useFetchCollection";
import { useDispatch, useSelector } from "react-redux";
import { STORE_ORDERS, selectOrderHistory } from "../../context/Slice/orderSlice";
import { selectUserID } from "../../context/Slice/authSlice";
import { useNavigate, Link } from "react-router-dom";




const OrderHistory = () => {
    const {data} = useFetchCollection("orders");
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const orders = useSelector(selectOrderHistory);
    const userID = useSelector(selectUserID);

    useEffect(() =>{
        dispatch(STORE_ORDERS(data));
    }, [dispatch, data]);


    const handleClick = (id) =>{
        navigate(`/order-details/${id}`)
    }

    const filteredOrders = orders.filter((order) => order.userID === userID)


    return (
        <>
            <section>
                <nav className="flex mt-8 mb-4" aria-label="Breadcrumb">
                    <ol className="inline-flex items-center space-x-1 md:space-x-3">
                        <li className="inline-flex items-center">
                        <Link to="/#products" className="inline-flex items-center text-sm font-medium text-gray-700 hover:text-blue-600">
                            <svg aria-hidden="true" className="w-4 h-4 mr-2" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"></path></svg>
                            Home
                        </Link>
                        </li>
                        
                        <li aria-current="page">
                        <div className="flex items-center">
                            <svg aria-hidden="true" className="w-6 h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd"></path></svg>
                            <span className="ml-1 text-sm font-medium text-gray-500 md:ml-2 ">Orders</span>
                        </div>
                        </li>
                    </ol>
                </nav>
                
                <div className="relative overflow-x-auto shadow-md sm:rounded-lg ">
                    <table className="w-full text-sm text-left text-gray-500 px-3 py-3">
                        <caption className="p-8 text-lg font-semibold text-left text-gray-900 bg-white  ">
                            Order History
                            <p className="mt-1 text-sm font-normal text-gray-500 ">Click on order to view your order details and leave a Product Review</p>
                        </caption>
                        
                        {filteredOrders.length === 0 ? (
                            <h2 className="text-gray-800 text-lg">No order found</h2>
                        ) : (
                            <>
                                <thead className="text-xs text-gray-700 bg-gray-50 border-gray-300 border-b border-t ">
                                <tr>
                                    <th scope="col" className="px-2 py-3">
                                        S/No.
                                    </th>
                                    <th scope="col" className="px-4 py-3 sm:table-cell hidden">
                                        Date
                                    </th>
                                    <th scope="col" className="px-4 py-3 sm:hidden table-cell">
                                        OrderDetails
                                    </th>
                                    <th scope="col" className="px-6 py-3 sm:table-cell hidden">
                                        Order ID
                                    </th>
                                    <th scope="col" className="px-6 py-3 sm:table-cell hidden">
                                        Order Amount 
                                    </th>
                                    
                                    <th scope="col" className="px-6 py-3 sm:table-cell hidden">
                                        Order status
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {filteredOrders.map((order, index) =>{
                                    const {id, orderDate, orderTime, orderAmount, orderStatus} = order;
                                    return(
                                        <tr key={id} onClick={() => handleClick(id)} className="bg-white border-b cursor-pointer hover:bg-gray-100 ">
                                            <td className="px-2 py-4">
                                                {index + 1}
                                            </td>
                                            <td className="sm:table-cell hidden px-4 py-4  text-gray-900 whitespace-nowrap font-medium">
                                                {orderDate} at {orderTime}
                                            </td>
                                            <td className="sm:hidden table-cell px-4 py-4  text-gray-900 whitespace-nowrap ">
                                                <b>Order Date: &nbsp;</b>{orderDate} at {orderTime}
                                                <tr>
                                                    <td><b>Order ID: &nbsp;</b>{id}</td>
                                                </tr>
                                                <tr>
                                                    <td><b>Order Amount: &nbsp;</b>{`₦${orderAmount.toFixed(2)}`}</td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <b>Order Status: &nbsp;</b>
                                                        <span className={ orderStatus !=="Delivered" ? `bg-yellow-100 text-yellow-800 text-sm font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-yellow-900 dark:text-yellow-300 hover:text-white hover:bg-yellow-900` : ` bg-green-200 text-green-800 text-sm font-medium mr-2 px-2.5 py-0.5 rounded cursor-pointer hover:text-white hover:bg-green-900`}>{orderStatus}</span>

                                                    </td>
                                                </tr>
                                            </td>

                                            <td className="px-6 py-4 sm:table-cell hidden">
                                                {id}
                                            </td>
                                            <td className="px-6 py-4 sm:table-cell hidden">
                                                {`₦${orderAmount.toFixed(2)}`}
                                            </td>
                                            
                                            <td className="px-6 py-4 sm:table-cell hidden">
                                                
                                                    <span className={ orderStatus !=="Delivered" ? `bg-yellow-100 text-yellow-800 text-sm font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-yellow-900 dark:text-yellow-300 hover:text-white hover:bg-yellow-900` : ` bg-green-200 text-green-800 text-sm font-medium mr-2 px-2.5 py-0.5 rounded cursor-pointer hover:text-white hover:bg-green-900`}>{orderStatus}</span>
                                                
                                                
                                                
                                                
                                            </td>
                                        </tr>
                                    )
                                })}
                                
                            </tbody>
                            </>
                        )}
                        
                    </table>
                </div>

            </section>
        </>
    )
};

export default OrderHistory;