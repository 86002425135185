import React, {useState} from 'react';
import { CountryDropdown, RegionDropdown } from "react-country-region-selector";
import { useDispatch } from "react-redux";
import { SAVE_SHIPPING_ADDRESS, SAVE_BILLING_ADDRESS } from "../../context/Slice/checkoutSlice";
import { useNavigate } from "react-router-dom";
import CheckoutSummary from "./CheckoutSummary";


const initialAddress = {
    name: "",
    phone: "",
    line1: "",
    line2: "",
    city: "",
    state: "",
    postal_code: "",
    country: "",

}


const CheckoutDetails = () => {


    const [shippingAddress, setShippingAddress] = useState({...initialAddress});
    const [billingAddress, setBillingAddress] = useState({...initialAddress});
    const dispatch = useDispatch();
    const navigate = useNavigate();

    //######### Functions #################
    const handleShipping = (e) => {
        const {name, value} = e.target
        setShippingAddress({
            ...shippingAddress,
            [name] : value
        })
    };
    const handleBilling = (e) => {
        const {name, value} = e.target
        setBillingAddress({
            ...billingAddress,
            [name] : value
        })
    };
    const handleSubmit = (e) => {
        e.preventDefault();
        
        dispatch(SAVE_SHIPPING_ADDRESS(shippingAddress));
        dispatch(SAVE_BILLING_ADDRESS(billingAddress));
        navigate("/checkout");
    };





  return (
    <section>
      <h1 className="text-lg font-semibold text-gray-600">Checkout Details</h1>
      <div className="flex lg:flex-row flex-col gap-8 w-screen mt-4 ">
        <br />
        <div className="rounded-lg shadow-md border border-gray-300 w-[365px] lg:w-[620px] px-8 py-8 ">
          <h1 className="text-md  text-blue-600 mb-4 border-b-2 border-t-2 px-2.5 py-2.5 ">Shipping Address</h1>
          <form onSubmit={handleSubmit}>
            <div className="grid md:grid-cols-2 md:gap-6">
              <div className="relative z-0 w-full mb-6 group">
                <input
                  type="text"
                  name="name"
                  id="floating_first_name"
                  value={shippingAddress.name}
                  onChange={(e) => handleShipping(e)}
                  className="block py-2.5 px-2 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-500 appearance-none  focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                  placeholder=" "
                  required
                />
                <label
                  htmlFor="floating_first_name"
                  className="peer-focus:font-medium absolute text-sm text-gray-500  duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                >
                  Recipient name
                </label>
              </div>

              {/*<div className="relative z-0 w-full mb-6 group">
                <input
                  type="text"
                  name="floating_last_name"
                  id="floating_last_name"
                  className="block py-2.5 px-2 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-500 appearance-none  focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                  placeholder=" "
                  required
                />
                <label
                  htmlFor="floating_last_name"
                  className="peer-focus:font-medium absolute text-sm text-gray-500  duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                >
                  Last name
                </label>
              </div>*/}
            </div>
            <div className="grid md:grid-cols-2 md:gap-6">
              <div className="relative z-0 w-full mb-6 group">
                <input
                  type="tel"
                  
                  name="phone"
                  id="floating_phone"
                  value={shippingAddress.phone}
                  onChange={(e) => handleShipping(e)}
                  className="block py-2.5 px-2 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-500 appearance-none  focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                  placeholder=" "
                  required
                />
                <label
                  htmlFor="floating_phone"
                  className="peer-focus:font-medium absolute text-sm text-gray-500  duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                >
                  Phone (080-6791-0000)
                </label>
              </div>
              <div className="relative z-0 w-full mb-6 group">
                <input
                  type="email"
                  name="email"
                  id="floating_email"
                  value={shippingAddress.email}
                  onChange={(e) => handleShipping(e)}
                  className="block py-2.5 px-2 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-500 appearance-none  focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                  placeholder=" "
                  required
                />
                <label
                  htmlFor="floating_email"
                  className="peer-focus:font-medium absolute text-sm text-gray-500  duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                >
                  Email Address
                </label>
              </div>
            </div>
            <div className="grid md:grid-cols-2 md:gap-6">
              <div className="relative z-0 w-full mb-6 group">
                <input
                  type="text"
                  name="postal_code"
                  id="floating_postal_code"
                  value={shippingAddress.postal_code}
                  onChange={(e) => handleShipping(e)}
                  className="block py-2.5 px-2 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-500 appearance-none  focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                  placeholder=" "
                  required
                />
                <label
                  htmlFor="floating_postal_code"
                  className="peer-focus:font-medium absolute text-sm text-gray-500  duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                >
                  Postal Code
                </label>
              </div>
              <div className="relative z-0 w-full mb-6 group">
                <select
                  id="floating_company"
                  placeholder=" "
                  name="city"
                  value={shippingAddress.city}
                  onChange={(e) => handleShipping(e)}
                  className="block py-2.5 px-2 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-500 appearance-none  focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                  required
                >
                  <option> Select Location </option>
                  <option value="Asokoro">Asokoro</option>
                  <option value="Central Area">Central Area</option>
                  <option value="Garki I">Garki I</option>
                  <option value="Wuse 1">Wuse 1</option>
                  <option value="Garki II">Garki II</option>
                  <option value="Maitama">Maitama</option>
                  <option value="Guzape">Guzape</option>
                  <option value="Maitama II A10">Maitama II A10</option>
                  <option value="Gudu ">Gudu </option>
                  <option value="Durumi">Durumi</option>
                  <option value="Wuye">Wuye</option>
                  <option value="Jabi">Jabi</option>
                  <option value="Utako">Utako</option>
                  <option value="Mabuchi">Mabuchi</option>
                  <option value="Jahi">Jahi</option>
                  <option value="Kado">Kado</option>
                  <option value="Dakibiyu">Dakibiyu</option>
                  <option value="Gaduwa">Gaduwa</option>
                  <option value="Dutse">Dutse</option>
                  <option value="Katampe Ext">Katampe Ext</option>
                  <option value="Karmo">Karmo</option>
                  <option value="Gwarimpa">Gwarimpa</option>
                  <option value="Dape">Dape</option>
                  <option value="Kafe">Kafe</option>
                  <option value="Galadimawa">Galadimawa</option>
                  <option value="Lokogoma">Lokogoma</option>
                  <option value="Idu">Idu</option>
                  <option value="Nyanya">Nyanya</option>
                  <option value=" Karu"> Karu</option>
                  <option value=" Kubwa"> Kubwa</option>
                  <option value=" Jukwoyi"> Jukwoyi</option>
                  <option value="Lugbe">Lugbe</option>
                  <option value="Apo">Apo</option>
                  
                </select>

                <label
                  htmlFor="floating_company"
                  className="peer-focus:font-medium absolute text-sm text-gray-500  duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                >
                  Location
                </label>
              </div>
            </div>

            <div className="grid md:grid-cols-2 md:gap-6">
              <div className="relative z-0 w-full mb-6 group">
                <CountryDropdown
                  valueType="full"
                  value={shippingAddress.country}
                  className="block py-2.5 px-2 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-500 appearance-none  focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                  required
                  onChange={(val) =>
                    handleShipping({
                      target: {
                        name: "country",
                        value: val,
                      },
                    })
                  }
                />

                <label
                  htmlFor="floating_company"
                  className="peer-focus:font-medium absolute text-sm text-gray-500  duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                >
                  Country
                </label>
              </div>
              <div className="relative z-0 w-full mb-6 group">
                <RegionDropdown
                  country={shippingAddress.country}
                  valueType="short"
                  value={shippingAddress.state}
                  blankOptionLabel="Your state"
                  className="block py-2.5 px-2 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-500 appearance-none  focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                 
                  onChange={(val) =>
                    {handleShipping({
                      target: {
                        name: "state",
                        value: val,
                      },
                    });
                    this.selectRegion(val)
                  }
                  }
                />

                <label
                  htmlFor="floating_company"
                  className="peer-focus:font-medium absolute text-sm text-gray-500  duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                >
                  State
                </label>
              </div>
            </div>

            <div className="relative z-0 w-full mb-6 group">
              <input
                type="text"
                name="line1"
                id="floating_password"
                value={shippingAddress.line1}
                onChange={(e) => handleShipping(e)}
                className="block py-2.5 px-2 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-500 appearance-none  focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                placeholder=" "
                required
              />
              <label
                htmlFor="floating_address1"
                className="peer-focus:font-medium absolute text-sm text-gray-500  duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
              >
                Address Line 1
              </label>
            </div>
            <div className="relative z-0 w-full mb-6 group">
              <input
                type="text"
                name="line2"
                id="floating_repeat_address2"
                value={shippingAddress.line2}
                onChange={(e) => handleShipping(e)}
                className="block py-2.5 px-2 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-500 appearance-none  focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                placeholder=" "
              />
              <label
                htmlFor="floating_repeat_address2"
                className="peer-focus:font-medium absolute text-sm text-gray-500  duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
              >
                Address Line 2
              </label>
            </div>

            {/* Billing Address */}
            <h1 className="text-md  text-blue-600 mb-4 border-b-2 border-t-2 px-2.5 py-2.5">Billing Address</h1>

            <div className="grid md:grid-cols-2 md:gap-6">
              <div className="relative z-0 w-full mb-6 group">
                <input
                  type="text"
                  name="name"
                  id="floating_first_name"
                  value={billingAddress.name}
                  onChange={(e) => handleBilling(e)}
                  className="block py-2.5 px-2 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-500 appearance-none  focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                  placeholder=" "
                  required
                />
                <label
                  htmlFor="floating_first_name"
                  className="peer-focus:font-medium absolute text-sm text-gray-500  duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                >
                  Full Name
                </label>
              </div>

             
            </div>
            <div className="grid md:grid-cols-2 md:gap-6">
              <div className="relative z-0 w-full mb-6 group">
                <input
                  type="tel"
                  
                  name="phone"
                  id="floating_phone"
                  value={billingAddress.phone}
                  onChange={(e) => handleBilling(e)}
                  className="block py-2.5 px-2 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-500 appearance-none  focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                  placeholder=" "
                  required
                />
                <label
                  htmlFor="floating_phone"
                  className="peer-focus:font-medium absolute text-sm text-gray-500  duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                >
                  Phone (080-6791-0000)
                </label>
              </div>
              <div className="relative z-0 w-full mb-6 group">
                <input
                  type="email"
                  name="email"
                  id="floating_email"
                  value={billingAddress.email}
                  onChange={(e) => handleBilling(e)}
                  className="block py-2.5 px-2 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-500 appearance-none  focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                  placeholder=" "
                  required
                />
                <label
                  htmlFor="floating_email"
                  className="peer-focus:font-medium absolute text-sm text-gray-500  duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                >
                  Email Address
                </label>
              </div>
            </div>
            <div className="grid md:grid-cols-2 md:gap-6">
              <div className="relative z-0 w-full mb-6 group">
                <input
                  type="text"
                  name="postal_code"
                  id="floating_postal_code"
                  value={billingAddress.postal_code}
                  onChange={(e) => handleBilling(e)}
                  className="block py-2.5 px-2 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-500 appearance-none  focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                  placeholder=" "
                  required
                />
                <label
                  htmlFor="floating_postal_code"
                  className="peer-focus:font-medium absolute text-sm text-gray-500  duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                >
                  Postal Code
                </label>
              </div>
              <div className="relative z-0 w-full mb-6 group">
                <select
                  id="floating_company"
                  placeholder=" "
                  name="city"
                  value={billingAddress.city}
                  onChange={(e) => handleBilling(e)}
                  className="block py-2.5 px-2 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-500 appearance-none  focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                  required
                >
                  <option> Select city </option>
                  <option value="Asokoro">Asokoro</option>
                  <option value="Central Area">Central Area</option>
                  <option value="Garki I">Garki I</option>
                  <option value="Wuse 1">Wuse 1</option>
                  <option value="Garki II">Garki II</option>
                  <option value="Maitama">Maitama</option>
                  <option value="Guzape">Guzape</option>
                  <option value="Maitama II A10">Maitama II A10</option>
                  <option value="Gudu ">Gudu </option>
                  <option value="Durumi">Durumi</option>
                  <option value="Wuye">Wuye</option>
                  <option value="Jabi">Jabi</option>
                  <option value="Utako">Utako</option>
                  <option value="Mabuchi">Mabuchi</option>
                  <option value="Jahi">Jahi</option>
                  <option value="Kado">Kado</option>
                  <option value="Dakibiyu">Dakibiyu</option>
                  <option value="Gaduwa">Gaduwa</option>
                  <option value="Dutse">Dutse</option>
                  <option value="Katampe Ext">Katampe Ext</option>
                  <option value="Karmo">Karmo</option>
                  <option value="Gwarimpa">Gwarimpa</option>
                  <option value="Dape">Dape</option>
                  <option value="Kafe">Kafe</option>
                  <option value="Galadimawa">Galadimawa</option>
                  <option value="Lokogoma">Lokogoma</option>
                  <option value="Idu">Idu</option>
                  <option value="Nyanya">Nyanya</option>
                  <option value=" Karu"> Karu</option>
                  <option value=" Kubwa"> Kubwa</option>
                  <option value=" Jukwoyi"> Jukwoyi</option>
                  <option value="Lugbe">Lugbe</option>
                  <option value="Apo">Apo</option>
                </select>

                <label
                  htmlFor="floating_company"
                  className="peer-focus:font-medium absolute text-sm text-gray-500  duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                >
                  City
                </label>
              </div>
            </div>

            <div className="grid md:grid-cols-2 md:gap-6">
              <div className="relative z-0 w-full mb-6 group">
                <CountryDropdown
                  valueType="full"
                  value={billingAddress.country}
                  className="block py-2.5 px-2 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-500 appearance-none  focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                  required
                  onChange={(val) =>
                    handleBilling({
                      target: {
                        name: "country",
                        value: val,
                      },
                    })
                  }
                />

                <label
                  htmlFor="floating_company"
                  className="peer-focus:font-medium absolute text-sm text-gray-500  duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                >
                  Country
                </label>
              </div>
              <div className="relative z-0 w-full mb-6 group">
                <RegionDropdown
                  country={billingAddress.country}
                  valueType="short"
                  value={billingAddress.state}
                  blankOptionLabel="Your state"
                  className="block py-2.5 px-2 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-500 appearance-none  focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                  
                  onChange={(val) =>
                    handleBilling({
                      target: {
                        name: "state",
                        value: val,
                      },
                    })
                  }
                />

                <label
                  htmlFor="floating_company"
                  className="peer-focus:font-medium absolute text-sm text-gray-500  duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                >
                  State
                </label>
              </div>
            </div>

            <div className="relative z-0 w-full mb-6 group">
              <input
                type="text"
                name="line1"
                id="floating_password"
                value={billingAddress.line1}
                onChange={(e) => handleBilling(e)}
                className="block py-2.5 px-2 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-500 appearance-none  focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                placeholder=" "
                required
              />
              <label
                htmlFor="floating_address1"
                className="peer-focus:font-medium absolute text-sm text-gray-500  duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
              >
                Address Line 1
              </label>
            </div>
            <div className="relative z-0 w-full mb-6 group">
              <input
                type="text"
                name="line2"
                id="floating_repeat_address2"
                value={billingAddress.line2}
                onChange={(e) => handleBilling(e)}
                className="block py-2.5 px-2 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-500 appearance-none  focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                placeholder=" "
              />
              <label
                htmlFor="floating_repeat_address2"
                className="peer-focus:font-medium absolute text-sm text-gray-500  duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
              >
                Address Line 2
              </label>
            </div>

            <button
              type="submit"
              className="text-white bg-pink-600 hover:bg-pink-800 focus:ring-4 focus:outline-none focus:ring-pink-300 font-medium rounded-lg text-sm w-full lg:w-[300px] sm:w-auto px-5 py-2.5 text-center lg:float-right"
            >
              Proceed to checkout <span className="float-right">&rarr;</span>
            </button>
          </form>
        </div>


        <CheckoutSummary />

        
      </div>
    </section>
  );
}

export default CheckoutDetails;