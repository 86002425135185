import React, { useState } from 'react';

const Pagination = ({currentPage, setCurrentPage, productsPerPage, totalProducts}) => {

    const totalPages = totalProducts / productsPerPage;

    const pageNumbers = [];

    //###### Limit the page number shown #####, setPageNumberLimit
    const [pageNumberLimit] = useState(5);
    const [maxNumberLimit, setMaxNumberLimit] = useState(5);
    const [minNumberLimit, setMinNumberLimit] = useState(0);

    //###### Paginate ##########
    const paginate = (pageNumber) =>{
        setCurrentPage(pageNumber);
    }

    //###### GO to next page #######
    const paginateNext = () =>{
        setCurrentPage(currentPage + 1);
        //###### Show next set of PageNumbers #########
        if(currentPage + 1 > maxNumberLimit){
            setMaxNumberLimit(maxNumberLimit + pageNumberLimit);
            setMinNumberLimit(minNumberLimit + pageNumberLimit);
        }

    }


    //###### Go to Prev page #######
    const paginatePrev = () =>{
        setCurrentPage(currentPage - 1);
         //###### Show Prev set of PageNumbers #########
        if((currentPage - 1) % pageNumberLimit === 0){
            setMaxNumberLimit(maxNumberLimit - pageNumberLimit);
            setMinNumberLimit(minNumberLimit - pageNumberLimit);
        }

    }



      for(let i = 1; i <= Math.ceil(totalProducts / productsPerPage); i++){
          pageNumbers.push(i);
      }



  return (
    <nav
      aria-label="Page navigation example"
      className="flex flex-col justify-center items-center mt-8"
    >
      <ul className="inline-flex -space-x-px">
        <li onClick={() => paginatePrev()}  className={currentPage === pageNumbers[0] ? `hidden` : `px-3 py-2 ml-0 leading-tight text-gray-500 font-light bg-white border border-gray-300 rounded-l-lg hover:bg-gray-100 hover:text-gray-700 cursor-pointer `}>
          Previous
        </li>

        {pageNumbers.map((number) => {
          if (number < maxNumberLimit + 1 && number > minNumberLimit) {
            return (
              <li
                key={number}
                onClick={() => paginate(number)}
                className={
                  currentPage === number
                    ? `px-3 py-2 leading-tight text-white font-light bg-pink-600 border border-gray-300  cursor-pointer duration-1000 transition-all ease-in-out`
                    : `px-3 py-2 leading-tight text-gray-500 font-light bg-white border border-gray-300  cursor-pointer`
                }
              >
                {number}
              </li>
            );
          }
        })}

        <li onClick={() => paginateNext()} className={currentPage === pageNumbers[pageNumbers.length - 1] ? `hidden` : `px-3 py-2 leading-tight text-gray-500 font-light bg-white border border-gray-300 rounded-r-lg hover:bg-gray-100 hover:text-gray-700 cursor-pointer`}>
          Next
        </li>
      </ul>
      <div className="mt-4">
        <span className="text-sm text-gray-700 font-light">
          Page <span className="font-semibold text-pink-500 ">{currentPage}</span>{" "}
          of{" "}
          <span className="font-semibold text-pink-500 ">
            {Math.ceil(totalPages)}
          </span>
        </span>
      </div>
    </nav>
  );
}

export default Pagination;