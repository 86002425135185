import React, { useState, useEffect } from 'react';

import { MdOutlineKeyboardBackspace} from 'react-icons/md';
import { RiRefreshFill } from 'react-icons/ri';
import { motion } from 'framer-motion';
import EmptyCart from "../img/emptyCart.svg";




import { useSelector, useDispatch } from "react-redux";
import { ADD_TO_CART, DECREASE_CART, REMOVE_FROM_CART, CLEAR_CART, selectCartItems, selectCartTotalQuantity, selectCartTotalAmount, CALCULATE_SUBTOTAL, CALCULATE_TOTAL_QUANTITY, SAVE_URL } from "../context/Slice/cartSlice";
import { Link, useNavigate } from "react-router-dom";
import { selectIsloggedIn } from '../context/Slice/authSlice';



import { BiMinus } from 'react-icons/bi';
import { BiPlus } from 'react-icons/bi';
import { useSnackbar } from 'notistack';





const CartContainer = () =>{

  const { enqueueSnackbar } = useSnackbar();

  const handleDeleteClick = () => {
      const message = 'You have removed one item from your cart';
        enqueueSnackbar(message, { autoHideDuration: 3000, variant: 'error',vertical: 'top', horizontal: 'center',anchorOrigin: { vertical: 'top', horizontal: 'right'}}); 
    };
    const handleIncreaseClick = () => {
      const message = 'You have added one item to your cart';
        enqueueSnackbar(message, { autoHideDuration: 3000, variant: 'success',vertical: 'top', horizontal: 'center',anchorOrigin: { vertical: 'top', horizontal: 'right'}}); 
    };



    const cartItems = useSelector(selectCartItems);
    const cartTotalQuantity = useSelector(selectCartTotalQuantity);
    const cartTotalAmount = useSelector(selectCartTotalAmount);
    const isLoggedIn = useSelector(selectIsloggedIn);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    //###### Increase cart quantity ######

    const increaseCart = (cart) => {
        dispatch(ADD_TO_CART(cart));
    };

    //###### Decrease cart quantity ######

    const decreaseCart = (cart) => {
        dispatch(DECREASE_CART(cart));
    };

    //###### Delete item from cart ######

    const deleteFromCart = (cart) => {
        dispatch(REMOVE_FROM_CART(cart));
    };

    //###### Clear cart ######

    const clearCart = () => {
        dispatch(CLEAR_CART());
    };

    //###### Use Effect to calculate the sub total ######
     useEffect(() =>{
         dispatch(CALCULATE_SUBTOTAL());
         dispatch(CALCULATE_TOTAL_QUANTITY());
         dispatch(SAVE_URL(""));
     }, [dispatch, cartItems]);

    const url = window.location.href;
    
    
    const checkout = () => {
        if(isLoggedIn){
            navigate("/checkout-details");
        }
        else {
            dispatch(SAVE_URL(url));
            navigate("/login");
        }
    }














    
    
    

 

  

  

    
    return (
      <motion.div
        initial={{ opacity: 0, x: 200 }}
        animate={{ opacity: 1, x: 0 }}
        exit={{ opacity: 0, x: 200 }}
        className="fixed top-0 right-0 w-full md:w-[340px] h-screen bg-white rounded-tl-xl drop-shadow-lg flex flex-col z-[101] "
        
      >
        {/* ############## Upper Section ######### */}
        <div className="w-full flex items-center justify-between p-4 cursor-pointer">
          <motion.div whileTap={{ scale: 0.8 }} >
            <MdOutlineKeyboardBackspace className="text-textColor text-2xl" />
          </motion.div>
          <p className="text-textColor text-lg font-semibold">Cart</p>
          <motion.p
            whileTap={{ scale: 0.8 }}
            className="flex items-center gap-2 p-1 px-2 my-2 rounded-lg hover:shadow-md  cursor-pointer text-red-900  text-base "
            onClick={() => clearCart()}
          >
            Clear <RiRefreshFill className="animate-spin" />
          </motion.p>
        </div>
        {/* ############## End of Upper Section ######### */}
        {/* ############## Bottom Section ######### */}
        {cartItems && cartItems.length > 0 ? (
          <div className="w-full h-full  rounded-t-[2rem] flex flex-col">
            {/* ######### Cart Item  Section#########*/}
            <div className="w-full h-340 md:h-42 px-4 py-4 flex flex-col gap-3 overflow-y-scroll scrollbar-none">
              {/* ######### Cart Item #########*/}

              {cartItems &&
                cartItems.map((cart, index) => {
                  const { id, name, imageURL, price, cartQuantity } = cart;
                  return (
                    <div
                      key={id}
                      className="w-full p-1 px-2 rounded-lg text-gray-700 bg-gray-50 relative inline-flex items-center gap-2 hover:shadow-md shadow-md"
                    >
                      <motion.div
                        whileTap={{ scale: 0.6 }}
                        onClick={() => {
                          deleteFromCart(cart);
                          handleDeleteClick();
                        }}
                        className="absolute inline-flex items-center justify-center w-6 h-6 text-md font-bold text-pink-700  border-2 border-white rounded-full -top-2 -right-2 cursor-pointer "
                      >
                        <ion-icon name="trash-outline"></ion-icon>
                      </motion.div>
                      <img
                        src={imageURL}
                        alt="cart item"
                        className="w-15 h-15 max-w-[45px] rounded-full object-contain"
                      />
                      {/* Cart Item Name Section */}
                      <div className="flex flex-col gap-2">
                        <p className="text-sm text-gray-700">{name}</p>
                        <p className="text-sm block text-gray-700 font-semibold">
                          ₦ {(price * cartQuantity).toFixed(2)}
                        </p>
                      </div>
                      {/* ########### Cart Item - Button Section ##########*/}
                      <div className="group flex items-center gap-2 ml-auto cursor-pointer text-gray-700">
                        <motion.div
                          whileTap={{ scale: 0.7 }}
                          onClick={() => {
                            decreaseCart(cart);
                            handleDeleteClick();
                          }}
                          className="hover:bg-cartBg hover:p-2 hover:rounded-full hover:text-white p-2 text-gray-700"
                        >
                          <BiMinus />
                        </motion.div>
                        <p className="w-5 h-5 rounded-full p-3 bg-cartBg text-gray-50 flex items-center justify-center">
                          {cartQuantity}
                        </p>
                        <motion.div
                          whileTap={{ scale: 0.7 }}
                          onClick={() => {
                            increaseCart(cart);
                            handleIncreaseClick();
                          }}
                          className="hover:bg-cartBg hover:p-2 hover:rounded-full hover:text-white p-2 text-gray-700"
                        >
                          <BiPlus />
                        </motion.div>
                      </div>
                    </div>
                  );
                })}
            </div>
            {/* Cart Total Section */}
            <div className="w-full flex-1 bg-gray-50 rounded-t-[2rem] flex flex-col items-center justify-evenly px-8 py-2 shadow-xl">
              

              {/** ##### Divider ####### */}
              <div className="w-full border-b border-gray-600 my-2"></div>

              <div className="w-full flex items-center justify-between">
                <p className="text-gray-700 text-lg font-semibold">Sub total</p>
                <p className="text-gray-700 text-lg font-semibold">
                  ₦ {cartTotalAmount.toFixed(2)}
                </p>
              </div>
              {isLoggedIn ? (
                <>
                    
                      <motion.button
                        whileTap={{ scale: 0.8 }}
                        type="button"
                        className="w-full p-2 rounded-full bg-pink-600 text-gray-50 text-md my-2 hover:shadow-lg "
                      >
                        {" "}
                        <Link to="/checkout-details">Check Out <span aria-hidden="true"> &rarr;</span></Link>
                      </motion.button>
                  
                  <div className="w-full mt-1 justify-center text-center text-sm text-gray-100">
                    <Link to="/cart">
                      <button
                        type="button"
                        className="w-full p-2 rounded-full bg-blue-500  text-md my-2 hover:shadow-lg font-medium text-white  duration-200 transition-all ease-in-out"
                        
                      >
                        View Shopping Bag
                        <span aria-hidden="true"> &rarr;</span>
                      </button>
                    </Link>
                  </div>
                </>
              ) : (
                <motion.button
                  whileTap={{ scale: 0.8 }}
                  type="button"
                  className="w-full p-2 rounded-full bg-gradient-to-tr from-green-500 to-green-700 text-gray-50 text-lg my-2 hover:shadow-lg "
                >
                  {" "}
                  <Link to="/login">Login to checkout </Link>
                </motion.button>
              )}
            </div>
          </div>
        ) : (
          <div className="px-4 py-4 w-full h-full flex flex-col items-center justify-center gap-6">
            <img src={EmptyCart} className="w-300" alt="" />
            <p className="text-xl text-textColor font-semibold">
              <Link to="/#products">Add some items to your cart</Link>
            </p>
          </div>
        )}
      </motion.div>
    );
}

export default CartContainer;