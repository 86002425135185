import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { collection, query,  onSnapshot, orderBy  } from "firebase/firestore"; 
import { firestore, storage } from '../../../firebase.config';
import { Link } from "react-router-dom";
import { doc, deleteDoc } from "firebase/firestore";
import { ref, deleteObject } from "firebase/storage";
import caton from "../../../img/caton.png";
import  Notiflix from "notiflix";
import { useDispatch, useSelector } from "react-redux";
import { STORE_PRODUCTS, selectProducts } from "../../../context/Slice/productSlice";
import useFetchCollection from "../../../customeHook/useFetchCollection";
import {
  FILTER_BY_SEARCH,
  selectFilteredProducts,
  SORT_PRODUCTS,
} from "../../../context/Slice/filterSlice";
import Search from "../../search/Search";
import Pagination from "../../pagination/Pagination";



const ViewProducts = () => {
  const [search, setSearch] = useState("");
  const { data } = useFetchCollection("products")

 
  //const [isLoading, setIsLoading] = useState(false);
  const filteredProducts = useSelector(selectFilteredProducts);
  const products = useSelector(selectProducts);

  //##### Pagination states ########
    const [currentPage, setCurrentPage] = useState(1);
    const [productsPerPage, setProductsPerPage] = useState(8);
    //####### Get Current Products #######
    const indexOfLastProduct = currentPage * productsPerPage;
    const indexOfFirstProduct = indexOfLastProduct - productsPerPage;
    const currentProducts = filteredProducts.slice(indexOfFirstProduct, indexOfLastProduct);


  

  const dispatch = useDispatch();
  useEffect(() =>{
      dispatch(
          STORE_PRODUCTS({
            products: data,
        }));
  }, [dispatch, data]);


  useEffect(() =>{
        dispatch(FILTER_BY_SEARCH({products, search}));
    }, [dispatch, products, search]);



 




  //######## Deleting Product ###########
  const confirmDelete = (id, imageURL) =>{
    Notiflix.Confirm.show(
        'Delete Product !!!',
        'You are about to delete this product?',
        'Delete',
        'Cancel',
        function okCb() {
          deleteProduct(id, imageURL);
        },
        function cancelCb() {
          console.log("Delete canceled");
        },
        {
          width: '320px',
          borderRadius: '8px',
          backOverlay: true,
          backOverlayColor: 'rgba(0,0,0,0.8)',
          titleColor: '#ff025f',
          okButtonBackground: '#ff025f',
          cssAnimationStyle: 'zoom',
          // etc...
        },
      );
  }
  const deleteProduct = async(id, imageURL) =>{

    try{
      await deleteDoc(doc(firestore, "products", id));

      // Create a reference to the file to delete
      const storageRef = ref(storage, imageURL);
      // Delete the file
      await deleteObject(storageRef);
      toast.success(" Product deleted successfully ...");
                
    } 
    
    catch(error){
      toast.error(error.message);
    }

  }



  return (
    <>
      <div className="ml-40 px-6 py-4 w-screen  flex flex-grow flex-shrink flex-auto">
        <section className=" bg-white  h-[900px] lg:h-[870px] lg:w-[1090px] mb-12 rounded-lg shadow-xl">
          <div className="py-8 mx-8 max-w-2xl lg:py-8">
            <h2 className="mb-4 text-xl font-light text-gray-500 ">
              All Products
            </h2>

            <span>
              <b>{filteredProducts.length}</b> Products Found
            </span>

            {/* ######## Search ######## */}
            <div className="mt-2">
              <form className="flex">
                <div className="relative w-full">
                  <Search
                    value={search}
                    onChange={(e) => {
                      setSearch(e.target.value);
                    }}
                  />
                </div>
              </form>
            </div>

            <div className="overflow-auto lg:overflow-visible mt-8">
              {filteredProducts.length === 0 ? (
                <>
                  <img
                    src={caton}
                    className="h-60 w-80 mt-8 ml-40 justify-center items-center"
                    alt="Empty product"
                  />
                  <h1 className="text-lg mt-4 justify-center items-center text-center">
                    No Product Found
                  </h1>
                </>
              ) : (
                <table className="table-auto border border-gray-300 table text-gray-400 border-separate space-y-6 text-sm rounded-lg w-[1000px]">
                  <thead className="boder border-gray-500 text-gray-500">
                    <tr>
                      <th className="p-3 text-left">S/No</th>
                      <th className="p-3">Product Image</th>
                      <th className="p-3 text-left">Product Name</th>
                      <th className="p-3 text-left">Category</th>
                      <th className="p-3 text-left">Item Weight</th>
                      <th className="p-3 text-left">Price</th>

                      <th className="p-3 text-left">Action</th>
                    </tr>
                  </thead>

                  <tbody>
                    {currentProducts.map((product, index) => {
                      const {
                        id,
                        name,
                        price,
                        imageURL,
                        category,
                        itemweight,
                      } = product;
                      return (
                        <tr key={id} className="border-b border-gray-400">
                          <td className="p-3">{index + 1}</td>

                          <td className="p-3">
                            <div className="flex align-items-center">
                              <img
                                className="h-12 w-12  object-cover"
                                src={imageURL}
                                alt={name}
                              />
                            </div>
                          </td>

                          <td>
                            <div className="ml-3">
                              <div className="">{name}</div>
                            </div>
                          </td>

                          <td className="p-3">{category}</td>
                          <td className="p-3">{itemweight}kg</td>
                          <td className="p-3 font-bold">N{price}</td>

                          <td className="p-3 ">
                            <Link
                              to={`/Admin/add-Product/${id}`}
                              className="text-green-700 hover:text-green-700  mx-2 cursor-pointer"
                            >
                              <ion-icon name="create-outline">Edit</ion-icon>
                            </Link>
                            <span className="text-red-700 hover:text-red-700  ml-2 cursor-pointer">
                              <ion-icon
                                name="trash-outline"
                                onClick={() => confirmDelete(id, imageURL)}
                              >
                                Delete
                              </ion-icon>
                            </span>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              )}
              <Pagination
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                productsPerPage={productsPerPage}
                totalProducts={filteredProducts.length}
              />
            </div>
          </div>
        </section>
      </div>
    </>
  );
  
}

export default ViewProducts;