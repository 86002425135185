import React, {useState, useEffect} from 'react';
//import { BsGrid3X3GapFill} from "react-icons/bs"
import Search from "../search/Search";
import NotFound from '../../img/NotFound.svg';
import ProductItem from "./ProductItem";
import { useDispatch, useSelector } from "react-redux";
import { FILTER_BY_SEARCH, selectFilteredProducts, SORT_PRODUCTS } from "../../context/Slice/filterSlice";
import Pagination from '../pagination/Pagination';



const ProductList = ({products}) => {


    const [grid, setGrid] = useState(true);
    const [search, setSearch] = useState("");
    const [sort, setSort] = useState("latest");
    const filteredProducts = useSelector(selectFilteredProducts);



    //##### Pagination states ########
    const [currentPage, setCurrentPage] = useState(1);
    const [productsPerPage, setProductsPerPage] = useState(12);
    //####### Get Current Products #######
    const indexOfLastProduct = currentPage * productsPerPage;
    const indexOfFirstProduct = indexOfLastProduct - productsPerPage;
    const currentProducts = filteredProducts.slice(indexOfFirstProduct, indexOfLastProduct);

  




    const dispatch = useDispatch();

    useEffect(() =>{
        dispatch(SORT_PRODUCTS({products, sort}));
    }, [dispatch, products, sort]);

    useEffect(() =>{
        dispatch(FILTER_BY_SEARCH({products, search}));
    }, [dispatch, products, search]);


    
  return (
        <section className="bg-transparent " id="product">
            
            <div className="lg:container  gap-4 lg:px-6  py-8  w-screen items-center justify-center">

                
                
            
                <div className={ grid ? "w-full items-center justify-center  scroll-smooth overflow-x-hidden flex-wrap  pt-4" : "bg-green-500 w-full h-79"}>


                <div className="lg:flex lg:-mx-2 w-screen">
                    

                    <div className="lg:mt-0 lg:px-2 w-[340px] lg:w-[1000px] md:w-[600px] ">
                    
                        <div className="flex items-center justify-between text-sm  border-b border-gray-300 mb-8">
                            <span className="hidden md:flex cursor-pointer text-gray-400 text-lg">
                                <ion-icon name="grid" onClick={() => setGrid(true)}></ion-icon>
                                {/** <BsGrid3X3GapFill />*/}
                            </span>

                            {/**<span className="cursor-pointer text-gray-500 text-lg">
                                <ion-icon name="list" onClick={() => setGrid(false)}></ion-icon>
                            </span>**/}
                            
                            
                            <p className="hidden md:flex text-gray-500">{filteredProducts.length} Items</p>

                            {/** Search */}
                            <Search value={search} onChange={(e) => setSearch(e.target.value)}/>



                            <div className=" w-[160px] pl-2.5  mb-4 ml-4 lg:ml-0 border border-gray-300 rounded-full items-center font-light">
                                
                                <select className="font-light py-2.5 px-2.5 rounded-lg text-gray-700 bg-transparent focus:outline-none" value={sort} onChange={(e) => setSort(e.target.value)}>
                                    <option value="latest">Latest</option>
                                    <option value="lowest-price">Lowest Price</option>
                                    <option value="highest-price">Highest Price</option>
                                    <option value="a-z">A - Z</option>
                                    <option value="z-a">z - A</option>
                                    
                                </select>
                            </div>
                        </div>

                        
                    </div>
                </div>

                <div className={`w-[340px] lg:w-[960px] md:w-[520px] lg:ml-0 md:ml-20  grid grid-cols-2  md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 lg:gap-8  justify-center items-center transition-all ease-in-out duration-1500 `}>

                {currentProducts.length === 0 ? (
                       <div className="w-screen flex flex-col  items-center justify-center">
                            <img src={NotFound} className="h-340 -my-4 -ml-20" alt="Not Found" />
                            <p className="text-xl text-pink-700 font-semibold my-4 mt-8">
                            Items not Available
                            </p>
                        </div> 
                    ) : (
                        <>
                            {currentProducts.map((product) =>{
                                return(
                                    <div
                                        key={product?.id}
                                        className="w-[160px] lg:w-80 md:w-[230px] h-[230px] md:h-[180px] bg-card rounded-xl md:rounded-lg md:py-2 md:px-4 py-4 px-4 my-4 md:my-2 backdrop-blur-lg drop-shadow-lg hover:drop-shadow-lg flex flex-col items-center justify-center relative hover:shadow-xl transform  hover:bg-card transition duration-1000 group"
                                        >
                                        <ProductItem {...product} grid={grid} product={product} />

                                    </div>
                                )
                            })}
                        
                        </>
                    )}
                </div>

                    
                    
                    <Pagination 
                    currentPage = {currentPage}
                    setCurrentPage = {setCurrentPage}
                    productsPerPage = {productsPerPage}
                    totalProducts = {filteredProducts.length}
                
                />
                </div>
                
            </div>
        </section>
  )
}

export default ProductList;