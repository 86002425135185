import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { FILTER_BY_CATEGORY, FILTER_BY_BRAND, FILTER_BY_PRICE } from '../../context/Slice/filterSlice';
import  { selectProducts, selectMinPrice, selectMaxPrice } from "../../context/Slice/productSlice";
import { motion } from "framer-motion";















const ProductFilter = () => {
    const [category, setCategory] = useState("All");
    const [brand, setBrand] = useState("All");
    const [price, setPrice] = useState(10000);
    const products = useSelector(selectProducts);
    //const minPrice = useSelector(selectMinPrice);
    const maxPrice = useSelector(selectMaxPrice);
    const dispatch = useDispatch();


    const allCategories = [
        "All",
        ...new Set(products.map((product) => product.category))
    ]; 


    // const allBrands = [
    //     "All",
    //     ...new Set(products.map((product) => product.brand))
    // ];

    //console.log(allCategories);

    useEffect(() =>{
        dispatch(FILTER_BY_BRAND({products, brand}))
    }, [dispatch, products, brand]);


    useEffect(() => {
        dispatch(FILTER_BY_PRICE({products, price}))
    }, [dispatch, products, price]);

    
    const filterProducts = (cat) =>{
        setCategory(cat);
        dispatch(FILTER_BY_CATEGORY({products, category: cat}))
    }


    // const clearFilters = () =>{
    //     setCategory("All");
    //     setBrand("All");
    //     setPrice(maxPrice);
    // }
const [active, setActive] = useState(0);

  return (
    <>
      <div className="ml-4 md:ml-0 mt-8 justify-center items-center">
        {allCategories.map((cat, index) => {
          return (
            <motion.button
              whileTap={{ scale: 0.7 }}
              className={` ${
                cat === "All" && index === active
                  ? "items-center text-white justify-center bg-pink-500 border border-pink-600 hover:bg-pink-400 hover:text-white hover:border-pink-400 "
                  : ""
              }text-gray-800 text-md font-normal mr-2 px-3 py-1.5 rounded-full  border border-gray-400 cursor-pointer hover:bg-pink-500 hover:text-white hover:border-pink-500 duration-500 ${
                index === active &&
                "items-center text-white justify-center bg-pink-500 border border-pink-600 hover:bg-pink-400 hover:text-white hover:border-pink-400"
              }`}
              key={index}
              type="button"
              onClick={() => {
                filterProducts(cat);
                setActive(index);
              }}
            >
              {cat}
            </motion.button>
          );
        })}
        
        
      </div>
    </>
  );
}

export default ProductFilter;