import React, { useState, useEffect} from "react";
import HomeContainer from "./HomeContainer";
import { motion } from "framer-motion";
import { MdChevronLeft, MdChevronRight } from "react-icons/md";
import RowContainer from './RowContainer';
//import MenuContainer from './MenuContainer';
//import CartContainer from './CartContainer';
//import { useStateValue } from "../context/StateProvider";
import Product from "./product/Product";


const MainContainer = () => {
  //const [{ foodItems, cartShow }] = useStateValue();
  const [scrollValue, setScrollValue] = useState(0);
  const url = window.location.href;

  
  useEffect(() =>{
    const scrollToProducts = () =>{
      if(url.includes("#products")){
        window.scrollTo({
          top: 1000,
          behavior: "smooth"
        })
        return
      }
    };
    scrollToProducts();

  }, [url]);



  useEffect(() => {}, [scrollValue]); //,cartShow


  return (
    <div className="w-full h-auto flex flex-col items-center justify-center">
      <HomeContainer />

      <section className="w-full my-6">
        <div className="w-full flex items-center justify-between">
          <p className="text-2xl font-samibold capitalize text-headingColor relative before:absolute before:rounded-lg before:content before:w-32 before:h-1 before:-bottom-2 before:left-0 before:bg-gradient-to-tr from-pink-400 to-pink-600 transition-all ease-in-out duration-1000">
            Our Fresh & Healthy Fruits
          </p>

          <div className="hidden md:flex gap-3 items-center">
            <motion.div
              whileTap={{ scale: 0.8 }}
              className="w-8 h-8 rounded-lg bg-pink-300 hover:bg-pink-500 cursor-pointer hover:shadow-lg flex items-center justify-center"
              onClick={() => setScrollValue(200)}
            >
              <MdChevronLeft className="text-lg text-white" />
            </motion.div>

            <motion.div
              whileTap={{ scale: 0.8 }}
              className="w-8 h-8 rounded-lg bg-pink-300 hover:bg-pink-500 cursor-pointer  hover:shadow-lg flex items-center justify-center"
              onClick={() => setScrollValue(-200)}
            >
              <MdChevronRight className="text-lg text-white" />
            </motion.div>
          </div>
        </div>
        <RowContainer
          scrollValue={scrollValue}
          flag={true}
          
        />
        {/* RowContainer data={foodItems?.filter((n) => n.category === "fruit")} */}
      </section>
      {/* ########### Menu <MenuContainer /> ####### */}
      

      {/* ########### Cart Container ####### */}
      {/*cartShow && <CartContainer />*/}

      <Product />

      
      
    </div>
  );
};

export default MainContainer;
