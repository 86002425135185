import React, {  useState } from "react"; //useEffect,
import { toast } from "react-toastify";
import { firestore } from '../../../firebase.config';
import { doc, setDoc, Timestamp } from "firebase/firestore";
import { useSelector } from "react-redux"; //, useDispatch
import {
  
  selectEmail,
  selectUsername,
} from "../../../context/Slice/authSlice"; //selectUserID,
import {  selectCartTotalAmount } from "../../../context/Slice/cartSlice";   //selectCartItems,
import {  selectShippingAddress } from "../../../context/Slice/checkoutSlice";  //selectBillingAddress,
import { useNavigate } from "react-router-dom";




const ChangeOrderStatus = ({order, id}) =>{
    
    
    const cartItems = order.cartItems;
    const navigate = useNavigate();
    const [status, setStatus] = useState("");
    const [paymentStatus, setPaymentStatus] = useState("");
    //const [isLoading, setIsLoading] = useState(false);
    
    //const userID = useSelector(selectUserID);
    const userName = useSelector(selectUsername);
    const userEmail = useSelector(selectEmail);
    
   
    //const shipping = useSelector(selectShippingAddress);
    //const totalAmount = useSelector(selectCartTotalAmount);
    //const billing = useSelector(selectBillingAddress);
    





    //############# Sending Mail for invoice ###########################
  const cartTable = cartItems.map((item, index) => {
    const { id, name, price, cartQuantity } = item;
    

    return `<tr key=${id} className="border-gray-300 border-b-2" >
                <td>${index + 1}</td>
                <td>${name}</td>
                <td>${cartQuantity}</td>
                <td>${price.toFixed(2)}</td>
                <td>${(price * cartQuantity).toFixed(2)}</td>
            </tr>`;
  });

  const shippingAdd = Object.entries(order.shipping).map(([key, val], i) => {
    return `${val}`;
  });
  const billingAdd = Object.entries(order.billing).map(([key, val], i) => {
    return `${val}`;
  });

  

  const sendMail = () => {
    const today = new Date();
    const date = today.toDateString();
    const time = today.toLocaleTimeString();

    const ebody = `
      <h1 style='background:" #000"; border-radius: "0.25em"; color: "#FFF"; margin: "0 0 1em"; padding: "0.5em 0";'>Invoice</h1>
      <hr />
      <h3>Order # ${order.orderTempID}</h3>
      Dear Valuable Customer <br /> <br /> 
      Bravo! Your order has been successfully delivered <br/> 
      Below are your order details: 
      <br /><br />
      <b>Shipping to:</b>
      ${shippingAdd}
      <br />
      <b>Billing to:</b>
      ${billingAdd}
      <br />
      
      <b>Order Date:</b>
      ${order.orderDate}
      <br />
      <b>Order Time:</b>
      ${order.orderTime}
      <br />
      <b>Order Status:</b>
      ${status}
      <br /><br /> 
      <b>Delivered by:</b>
      ${userName} <br />
      Phone: (+2348167913802)
      <br />
      
      <p><b>Payment Status: </b>${paymentStatus}</p>
      <hr />
      <div key={id} class="col-md-12">
        <table
              className="table border-2 border-gray-300 px-4 py-4"
              style={{borderCollapse:"collapse",padding:"10px 10px",}}
            >
              <thead>
                <tr>
                  <th className="border-0 text-uppercase small font-weight-bold px-4 py-4">
                    ID
                  </th>
                  <th className="border-0 text-uppercase small font-weight-bold px-4 py-4">
                    Item
                  </th>
                  <th className="border-0 text-uppercase small font-weight-bold px-4 py-4">
                    Quantity
                  </th>
                  <th className="border-0 text-uppercase small font-weight-bold px-4 py-4">
                    Unit Cost
                  </th>
                  <th className="border-0 text-uppercase small font-weight-bold">
                    Total
                  </th>
                </tr>
              </thead>
              <tbody className="border-gray-300 border-b-2">${cartTable}</tbody>
              <tfoot>
                <tr>
                  <td colSpan="5"></td>
                </tr>
                <tr className="border-gray-300 border-b-2">
                  <td colSpan="2"></td>
                  <td colSpan="2"><b>SUBTOTAL</b></td>
                  <td>₦${order.totalAmount}</td>
                </tr>
                <tr className="border-gray-300 border-b-2">
                  <td colSpan="2"></td>
                  <td colSpan="2"><b>Service Charge 13%</b></td>
                  <td>₦${order.serviceCharge}</td>
                </tr>
                <tr className="border-gray-300 border-b-2">
                  <td colSpan="2"></td>
                  <td colSpan="2"><b>Delivery</b></td>
                  <td>₦${order.deliveryFee}</td>
                </tr>
                <tr className="border-gray-300 border-b-2">
                  <td colSpan="2"></td>
                  <td colSpan="2"><b>GRAND TOTAL</b></td>
                  <td>₦${order.orderAmount}</td>
                </tr>
              </tfoot>
            </table>
      </div>
      <br /><br />
      <b>Thank you</b>
      <br />
      <b>Urmall.ng Group</b>

      <br /> <br /> <br />
      <b>Info:</b>&nbsp; Hoping you will order again and get 10% discount.
      <br />
      <hr />
      <i>#Invoice was created on a computer and is valid without the signature and seal.</i>
    `;

    const configEmail = {
      SecureToken: process.env.REACT_APP_EMAIL_SECURE_TOKEN,
      To: `${userEmail}`,
      From: "urmall.ng@gmail.com",
      Cc: "abmusadeeq@outlook.com",
      Subject: `Delivered | INVOICE FOR YOUR ORDER: on ${date} by ${time}`,
      Body: ebody,
    };

    if (window.Email) {
      window.Email.send(configEmail).then(() => null);
    }
  };





    const editOrder = (e, id) => {
    //   const today = new Date()
    //   const date = today.toDateString();
    //   const time = today.toLocaleTimeString();
        e.preventDefault();
        //setIsLoading(true);

      const orderConfig = {
        userID: order.userID,
        userEmail: order.userEmail,
        cartItems: order.cartItems,
        shipping: order.shipping,
        billing: order.billing,
        orderTempID: order.orderTempID,
        orderAmount: order.orderAmount,
        serviceCharge: order.serviceCharge,
        orderDate: order.orderDate,
        orderTime: order.orderTime,
        orderStatus: status,
        PaymentStatus: paymentStatus,
        deliveryFee: order.deliveryFee,
        totalAmount: order.totalAmount,
        createdAt: order.createdAt,
        editedAt: Timestamp.now().toDate(),
      }

      try{

            
            //setIsLoading(false);
            
            
                // Add a new document with a generated id.
                if (status === "Delivered...") {
                    sendMail();
                }
                setDoc(doc(firestore, "orders", id), orderConfig);
                toast.success("Order status updated successfully");
                navigate("/Admin/Orders");
            
            
           

        }
        catch(error){
            toast.error(error.message);
            //setIsLoading(false);
        }
  };




    


    return (
      <>
        <div>
          <div className="card w-96 bg-white shadow-xl mt-8 py-4 px-4 rounded-lg">
            <div className="card-body">
              <form onSubmit={(e) => editOrder(e, id)}>
                <div>
                  <h2>Update Order Status</h2>
                  <select
                    value={status}
                    onChange={(e) => setStatus(e.target.value)}
                    className="select w-full max-w-xs mt-4 px-2.5 py-2.5 border border-gray-500 rounded-lg" required
                  >
                    <option value="Order Placed...">Order Placed...</option>
                    <option value="Processing...">Processing...</option>
                    <option value="Shipped...">Shipped...</option>
                    <option value="Delivered...">Delivered...</option>
                  </select>
                </div>
                <div className="mt-4">
                  <h2>Update Payment Status</h2>
                  <select
                    value={paymentStatus}
                    onChange={(e) => setPaymentStatus(e.target.value)}
                    className="select w-full max-w-xs mt-4 px-2.5 py-2.5 border border-gray-500 rounded-lg"  required
                  >
                    <option value="Pending...">Pending...</option>
                    <option value="Payed...">Payed...</option>
                    <option value="Not Payed...">Not Payed...</option>
                  </select>
                </div>

                <span>
                  <button className="bg-blue-500 px-4 py-3 mt-8 text-white rounded-lg">
                    Update status
                  </button>
                </span>
              </form>
            </div>
          </div>
        </div>
      </>
    );
};

export default ChangeOrderStatus;