import React, {useEffect, useState} from 'react';
import { useParams  } from "react-router-dom";
import { doc, getDoc } from "firebase/firestore";
import { firestore } from '../../firebase.config';
import { toast } from 'react-toastify';
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import { BiMinus } from 'react-icons/bi';
import { BiPlus } from 'react-icons/bi';
import { useDispatch, useSelector } from "react-redux";
import { ADD_TO_CART, DECREASE_CART, selectCartItems, CALCULATE_TOTAL_QUANTITY } from "../../context/Slice/cartSlice";
import useFetchData from "../../customeHook/useFetchData.jsx";
import useFetchCollection from "../../customeHook/useFetchCollection";
import StarsRating from 'react-star-rate';





const ProductDetails = () => {

    const {id} = useParams();
    const [product, setProduct ] = useState(null);
    const dispatch = useDispatch();
    const cartItems = useSelector(selectCartItems);
    const { docuument } = useFetchData("products", id);
    const { data } = useFetchCollection("reviews");
    
    


    const filteredReviews = data.filter((review) => review.productID === id)


    const cart = cartItems.find((cart) => cart.id === id);
    const isCartAdded = cartItems.findIndex((cart) => {
        return cart.id === id
    });


    const [show, setShow] = useState(false);
    const [show2, setShow2] = useState(false);


    

    // const getProduct = async() =>{
    //     const docRef = doc(firestore, "products", id);
    //     const docSnap = await getDoc(docRef);

    //     if (docSnap.exists()) {
    //     //console.log("Document data:", docSnap.data());
    //     const obj = {
    //         id: id,
    //         ...docSnap.data()
    //     }
    //     setProduct(obj);
    //     } else {
    //     toast.error("Pst! Product not found");
    //     }
    // }

    //############### Add to cart function #############
    const addToCart = (product) =>{
        dispatch(ADD_TO_CART(product));
        dispatch(CALCULATE_TOTAL_QUANTITY());
    }

    //############## Decrease Cart function ###########
    const decreaseCart = () => {
        dispatch(DECREASE_CART(product));
        dispatch(CALCULATE_TOTAL_QUANTITY());
    }

    useEffect(() =>{
        setProduct(docuument);
    }, [docuument])


  return (
    <div className="flex flex-col w-screen">
      <h2 className="lg:text-2xl text-xl font-semibold lg:leading-6 leading-7 text-gray-800 mt-2 mb-2">
        Product Details
      </h2>
      <Link to="/#products">&larr; Back to products</Link>
      
      <div className="md:flex items-start justify-center py-12 2xl:px-20 md:px-6 px-4">
        {product === null ? (
          <>
            <div className="rounded-full bg-pink-200 w-[177px] h-[177px] relative flex justify-center items-center mx-auto animate-spin">
              <svg
                className="absolute top-0 0 left-0"
                width={177}
                height={177}
                viewBox="0 0 177 177"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M169.667 88.5C173.717 88.5 177.032 85.2113 176.696 81.1755C175.457 66.2542 170.448 51.8477 162.085 39.332C152.361 24.7783 138.539 13.435 122.367 6.73666C106.196 0.0383073 88.4018 -1.71429 71.2345 1.7005C54.0672 5.11529 38.298 13.5441 25.9211 25.921C13.5441 38.298 5.1153 54.0672 1.7005 71.2345C-1.71429 88.4018 0.0383044 106.196 6.73666 122.367C13.435 138.539 24.7783 152.361 39.332 162.085C51.8477 170.448 66.2542 175.457 81.1755 176.696C85.2113 177.032 88.5 173.717 88.5 169.667V169.667C88.5 165.618 85.2089 162.373 81.1792 161.971C69.1624 160.774 57.5826 156.642 47.4795 149.891C35.3374 141.778 25.8738 130.247 20.2855 116.755C14.6971 103.264 13.2349 88.4181 16.0838 74.0955C18.9328 59.773 25.9649 46.6168 36.2909 36.2908C46.6169 25.9649 59.773 18.9328 74.0955 16.0838C88.4181 13.2349 103.264 14.6971 116.755 20.2855C130.247 25.8739 141.778 35.3375 149.891 47.4795C156.642 57.5826 160.774 69.1624 161.971 81.1793C162.373 85.209 165.618 88.5 169.667 88.5V88.5Z"
                  fill="#4338CA"
                />
              </svg>
              <div className="div rounded-full bg-slate-50 w-[150px] h-[150px]" />
            </div>
          </>
        ) : (
          <>
            <div className="xl:w-2/6 lg:w-2/5 w-80 md:block">
              
              <motion.div whileHover={{ scale: 1.1 }}>
                <img
                  className="w-[200px] mt-2"
                  alt={product.name}
                  src={product.imageURL}
                />
              </motion.div>
            </div>
            <div className="xl:w-2/5 md:w-1/2 lg:ml-8 md:ml-6 md:mt-0 mt-6">
              <div className="border-b border-gray-200 pb-6">
                <p className="text-sm leading-none text-gray-600">
                  {product.brand}
                </p>
                <h1
                  className="
                            lg:text-2xl
                            text-xl
                            font-semibold
                            lg:leading-6
                            leading-7
                            text-gray-800
                            mt-2
                        "
                >
                  {product.name}
                </h1>
              </div>
              <div className="py-4 border-b border-gray-200 flex items-center justify-between">
                <div>
                  <p className="xl:pr-48 text-base lg:leading-tight leading-normal text-gray-600">
                    {product.desc}.
                  </p>
                  <p className="text-sm text-gray-600 mt-2 font-semibold">
                    <span className="text-urmallColor">N</span>
                    {product.price}
                  </p>
                </div>

                {isCartAdded < 0 ? null : (<>
                            <div className="group flex items-center gap-2 ml-auto mr-4 cursor-pointer">
                        
                        <motion.div  onClick={() => decreaseCart(product)} whileTap={{ scale: 0.7 }}>
                            <BiMinus className="text-white rounded-xl bg-cartBg " />
                        </motion.div>

                        <p className="w-5 h-5 text-sm rounded-xl bg-cartBg text-gray-50 flex items-center justify-center">
                            {cart.cartQuantity}
                        </p>
                        
                        <motion.div
                            onClick={() => addToCart(product)}
                            whileTap={{ scale: 0.7 }}
                        >
                            <BiPlus className="text-white rounded-xl bg-cartBg" />
                        </motion.div>
                        
                        </div>
                    </>)}

                
              </div>

              <button
                onClick={() => addToCart(product)}
                className="
                          focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-800
                          text-base
                          flex
                          items-center
                          justify-center
                          leading-none
                          text-white
                          bg-pink-600
                          w-[300px]
                          lg:w-full
                          py-4
                          hover:bg-gray-700
                          rounded-xl
                      "
              >
                <span className="text-lg">
                  <ion-icon name="bag-handle"></ion-icon>
                </span>{" "}
                &nbsp;Add to cart
              </button>

              <div>
                <div className="w-full border-t border-b py-4 mt-7 border-gray-200">
                  <div
                    onClick={() => setShow(!show)}
                    className="flex justify-between items-center cursor-pointer"
                  >
                    <p className="text-base leading-4 text-gray-800">
                      Customer's Reviews'
                    </p>
                    <button
                      className="
                                  cursor-pointer
                                  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-400
                                  rounded
                              "
                      aria-label="show or hide"
                    >
                      <svg
                        className={
                          "transform " + (show ? "rotate-180" : "rotate-0")
                        }
                        width="10"
                        height="6"
                        viewBox="0 0 10 6"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M9 1L5 5L1 1"
                          stroke="#4B5563"
                          strokeWidth="1.25"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </button>
                  </div>
                  <div
                    className={
                      "pt-4 text-base leading-normal pr-12 mt-4 text-gray-600 " +
                      (show ? "block" : "hidden")
                    }
                    id="sect"
                  >
                    You will be responsible for paying for your own shipping
                    costs for returning your item. Shipping costs are
                    nonrefundable
                  </div>
                </div>
              </div>
              <div>
                <div className="border-b py-4 border-gray-200">
                  <div
                    onClick={() => setShow2(!show2)}
                    className="flex justify-between items-center cursor-pointer"
                  >
                    <p className="text-base leading-4 text-gray-800">
                      Contact us
                    </p>
                    <button
                      className="
                                cursor-pointer
                                focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-400
                                rounded
                            "
                      aria-label="show or hide"
                    >
                      <svg
                        className={
                          "transform " + (show2 ? "rotate-180" : "rotate-0")
                        }
                        width="10"
                        height="6"
                        viewBox="0 0 10 6"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M9 1L5 5L1 1"
                          stroke="#4B5563"
                          strokeWidth="1.25"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </button>
                  </div>
                  <div
                    className={
                      "pt-4 text-base leading-normal pr-12 mt-4 text-gray-600 " +
                      (show2 ? "block" : "hidden")
                    }
                    id="sect"
                  >
                    If you have any questions on how to return your item to us,
                    contact us.
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
        
      </div>
      <div className="flex card w-[1200px] -mt-8 bg-base-100 shadow-xl px-8 py-8 ">
          <div className="card-body">
            <p className="text-xl font-semibold text-gray-700 mb-4 border-b border-gray-300 py-4">Reviews</p>
            <div>
                    { filteredReviews.length === 0 ? (
                      <p>There are no reviews for this product yet.</p>
                    ) : (
                      <>
                        {filteredReviews.map((productReview, index) =>{
                          const {review, rate, productID, userName, reviewDate} = productReview;
                          return(
                            <div key={productID} className="border-b border-gray-400 mb-4 py-4">
                              <StarsRating value={rate} />
                              <p className="mb-4">{review}</p>
                              <p className="text-sm"><b>Date:  </b> {reviewDate}</p>
                              <p className="text-sm"><b>By:  </b> {userName}</p>

                              
                            </div>
                          )
                        })}

                      </>
                    )}
            
            </div>
          </div>
        </div>
    </div>
  );
}

export default ProductDetails;