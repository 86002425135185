import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import {BrowserRouter as Router } from 'react-router-dom';
import { StateProvider } from './context/StateProvider';
import { initialState } from './context/initialState';
import reducer from './context/reducer';
import { SnackbarProvider } from 'notistack';
import { Provider } from "react-redux";
import Store from './context/Store';



const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={Store}>
      <Router>
        <StateProvider initialState ={initialState} reducer={reducer}>
            <SnackbarProvider maxSnack={1} autoHideDuration={3000} dense>
              <App />
            </SnackbarProvider>
        </StateProvider>
    </Router>
  </Provider>
);

reportWebVitals();