import { doc, getDoc } from "firebase/firestore";
import { firestore } from '../firebase.config';
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";


const useFetchData = (collectionName, dataID) => {

    const [docuument, setDocument ] = useState(null);

   const getData = async() =>{
        const docRef = doc(firestore, collectionName, dataID);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
        //console.log("Document data:", docSnap.data());
        const obj = {
            id: dataID,
            ...docSnap.data()
        }
        setDocument(obj);
        } else {
        toast.error("Pst! Product not found");
        }
    };

    useEffect(() =>{
        getData()
    }, []);

    return { docuument}
}

export default useFetchData;