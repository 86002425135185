import React, { useState, useEffect } from "react";
//import { loadStripe } from "@stripe/stripe-js";
//import { Elements } from "@stripe/react-stripe-js"; 
import { useSelector, useDispatch } from "react-redux";
import { CALCULATE_SUBTOTAL, CALCULATE_TOTAL_QUANTITY, selectCartItems, selectCartTotalAmount } from "../../context/Slice/cartSlice";
import { selectEmail } from "../../context/Slice/authSlice";
import { selectBillingAddress, selectShippingAddress } from "../../context/Slice/checkoutSlice";
import { toast } from "react-toastify";
import CheckoutForm from "./CheckoutForm";



//const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PK);
const Checkout = () => {

  const dispatch = useDispatch();
  const [message, setMessage] = useState("Initializing Checkout");
  //const [clientSecret, setClientSecret] = useState("");

  const cartItems = useSelector(selectCartItems);
 
  const totalAmount = useSelector(selectCartTotalAmount);
  const customerEmail = useSelector(selectEmail);



  const billingAddress = useSelector(selectBillingAddress);
  const shippingAddress = useSelector(selectShippingAddress);

  useEffect(() =>{
    dispatch(CALCULATE_SUBTOTAL())
    dispatch(CALCULATE_TOTAL_QUANTITY())

  }, [dispatch, cartItems]);


  //const description = `Urmall.ng payment: Email: ${customerEmail}, Amount: ${totalAmount}`;


  // useEffect(() => {
  //   // Create PaymentIntent as soon as the page loads
  //   fetch("http://localhost:4242/create-payment-intent", {
  //     method: "POST",
  //     headers: { "Content-Type": "application/json" },
  //     body: JSON.stringify({ 
  //       items: cartItems,
  //       userEmail: customerEmail,
  //       shipping: shippingAddress,
  //       billing: billingAddress,
  //       description
  //      }),
  //   })
  //     .then((res) => {
  //       if(res.ok){
  //         return res.json()
  //       }
  //       return res.json().then((json) => Promise.reject(json))
  //     })
  //     .then((data) => {
  //       setClientSecret(data.clientSecret)
  //     })
  //     .catch((error) =>{
  //       setMessage("Failed to Initialized Checkout")
  //       toast.error("Oops! Something went wrong");
  //     })
  // }, []);

  // const appearance = {
  //   theme: 'stripe',
  // };
  // const options = {
  //   clientSecret,
  //   appearance,
  // };



  return (
    <>
    <h1 className="text-lg font-semibold text-gray-600">Checkout</h1>
      {/*<section>
        <div>
        {!clientSecret && <h3>{message}</h3>}
          
        </div>
      </section>
      {clientSecret && (
        <Elements options={options} stripe={stripePromise}>
          <CheckoutForm />
        </Elements>
      )}*/}

      <section>
        <CheckoutForm />
      </section>
      
    
    </>
    
  )
}

export default Checkout;